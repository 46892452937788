import React from 'react'
import { Link } from 'react-router-dom'
import pic from "../../images/Bell.png";
import "./footer.css"
import axios from 'axios';
import { useState, useEffect } from 'react';
import { baseUrl } from '../../API';

const Footer = () => {
  const [count, setCount] = useState([])

  useEffect(() => {
    axios.get(baseUrl+"api/general-setting/").then(response => {
      setCount(response.data.general);
    });
  }, []);
  return (
    <div>
      {count.map(projects => ( 
      <div className='footer'>
  
      <div className='footer_box'>
        <div className='footer_box_social'>
        <div className='push__footer_logo marg'>
        <div className="push__footer-links_logo">
          <Link to="/">
            <img src={baseUrl+projects.image} />
          </Link>
        </div>
            <p>{projects.title}</p>

            
            </div>
            <div className='footer_box_discover marg'>
                <h3 className='head'>Discover</h3>
                <ul>
                  <li><Link to='/'>Home</Link></li>
                  <li><Link  to='/project/list'>Project</Link></li>
                  <li><Link to='/learn'>Learning</Link></li>
                  <li><Link to='/contact'>Contact Us</Link></li>
                </ul>
            </div>
            <div className='footer_box_discover marg'>
                <h3 className='head'>Push</h3>
                <ul>
                  <li><Link target="_blank" to='https://gov.push.org/'>Forum</Link></li>
                  <li><Link target="_blank" to='https://snapshot.org/#/pushdao.eth'>Snapshot</Link></li>
                  <li><Link target="_blank" to='https://github.com/ethereum-push-notification-service'>GitHub</Link></li>
                  <li><Link target="_blank" to='https://docs.push.org/governance/push-governance/push-governance-guide'>Guide</Link></li>
                  <li><Link  target="_blank" to='https://push.org/faq'>FAQ</Link></li>
                </ul>
            </div>
            <div className='footer_box_help marg'>
              <h3 className='head'>Help Center</h3>
              <ul>
              <Link to={`tel:${projects.contact1}`} ><li ><i className="fa-solid fa-phone"></i> <p>{projects.contact1}</p> </li></Link>
              <Link target='_blank'  to='https://goo.gl/maps/V8wx6c6rDUdCR5bv9'><li><i className="fa-solid fa-location-dot"></i> <p>{projects.address1}</p> </li></Link>
              <a href={`mailto:${projects.email1}`} target="_blank"><li> <i class="fa-regular fa-envelope"></i> <p>{projects.email1}</p> </li></a>
                  
                </ul>
            </div>

            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28463.128156497383!2d75.71685601650373!3d26.906953575630443!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db49e043a7acb%3A0xdad09ace57371810!2sVaishali%20Nagar%2C%20Jaipur%2C%20Rajasthan!5e0!3m2!1sen!2sin!4v1684931532463!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            <div className='subscribe marg'>
              <h3 className='head' >Social Media</h3>
              <div className='footer_social'>
              <Link to={projects.facebook_link} target="_blank"><i className="fa-brands fa-facebook-f"></i></Link>

              <Link to={projects.instagram_link} target="_blank"><i className="fa-brands fa-instagram"></i></Link>
            
            <Link to={projects.twitter_link} target="_blank"><i className="fa-brands fa-twitter"></i></Link>

            <Link to={projects.linkdein_link} target="_blank"><i className="fa-brands fa-linkedin-in"></i></Link>
            
           
            </div>
            
            </div>
        </div>
      </div>
      
    </div>))}
   

  
    </div>
  )
}

export default Footer
