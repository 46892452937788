import React from 'react'
import Lottie from 'react-lottie'
import Coin from './roket.json'

export default function Des() {
  const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: Coin,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
  
  return (
    <>
      <Lottie 
	    options={defaultOptions}
        height={270}
        width={370}
      />
    </>
  );
}

