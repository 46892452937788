import React, { useState, useEffect } from "react";
import "./cta.css";

// import Fade from 'react-reveal/Fade';

import { motion } from 'framer-motion';
import { baseUrl } from "../../API";
import axios from "axios";

const Cta = () => {


  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    axios
      .get(baseUrl + 'api/chainlist/')
      .then((res) => {
        // Extracting "api_data" from the response data
        const apiData = res.data.api_data;
        setApiData(apiData);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data); // Log the response payload for error handling
        }
      });
  }, []);


  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <section className='cap_section universeal'>
     {apiData && ( <div className="cap_sec_div">
      <div className="cap_sec_card"> <span>Price </span>{apiData.price
            ? `$${formatNumberWithCommas(apiData.price.toFixed(4))}`
            : "$0.3,166"} </div>
    <div className="cap_sec_card"> <span>Volume </span>{apiData.volume_24h
            ? `$${formatNumberWithCommas(Math.trunc(apiData.volume_24h))}`
             : "$539,937"}</div>
    <div className="cap_sec_card"><span>Market Cap </span>{apiData.market_cap
          ? `$${formatNumberWithCommas(Math.trunc(apiData.market_cap))}`
          : "$10,315,592"}</div>
      </div>)}
    
</section>
//     <section className="sec">
//   {apiData && (
//     <div className="re">
//       <motion.div className="sec_sub">
//         <h4>
//           <span>Price</span>{" "}
//           {apiData.price
//             ? `$${formatNumberWithCommas(apiData.price.toFixed(4))}`
//             : "$0.3,166"}
//         </h4>
//       </motion.div>

//       <motion.div className="sec_sub">
//         <h4>
//           <span>Volume 24h</span>{" "}
//           {apiData.volume_24h
//             ? `$${formatNumberWithCommas(Math.trunc(apiData.volume_24h))}`
//             : "$539,937"}
//         </h4>
//       </motion.div>

//       <motion.div className="sec_sub">
//         <h4>
//           <span>Market Cap</span>{" "}
//           {apiData.market_cap
//             ? `$${formatNumberWithCommas(Math.trunc(apiData.market_cap))}`
//             : "$10,315,592"}
//         </h4>
//       </motion.div>
//     </div>
//   )}
// </section>
 
  );
};

export default Cta;