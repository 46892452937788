import React, { useState } from "react";

import axios from "axios";
import './article.css';




const Article = () =>{



  const [formValues, setFormValues] = useState({
    email: "",
    
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post("http://adminecosystem.uptimiseit.com:8000/api/subscribe-form/", formValues)
      .then((response) => {
        alert("Form submitted successfully!");
        setFormValues({ email: "" });
        window.location.reload();
      })
      .catch((error) => {
        alert("Error submitting form. Please try again.");
      });
  };


    return(
     
     <div className="subs_con" >
      <div className="subscribe_sec">
      <div className="subscribe_sec-txt">
        <h3>Subscibe to <br/> Our Newsletter</h3>
        <p>Get weekly update about our product on your email, no spam guaranteed we promise ✌️</p>
      </div>
      <div className="subscribe_sec-input">
      <form onSubmit={handleSubmit} className="subs-bar">
              <input type='email' placeholder='Email ' name='email' value={formValues.email}
        onChange={handleInputChange}/> 
              <button type="submit" className="butt">Subscribe</button>
              </form>
              <div><p>1500+ subscribed</p></div>
              
            </div>
            </div>
     </div>


    

      
    )
}

export default Article;