import React from 'react'
import Home from './Home'
import Project from './Project'
import './App.css'

import { Route, Routes } from 'react-router-dom'
import ProjectDetail from './ProjectDetail'
import Contact from './Contact'
import AddProject from './addProject/AddProject'
import Learn from './learn/Learn'
import Edit from './Edit'
import Report from './Report'
import MarketingForm from './MarketingForm'
import LearingDetail from './learing-detail/LearingDetail'


const App = () => {

  




  return (
    
    <div className='App' >
      <Routes>
      <Route path="/"  element={<Home />} />
      <Route path="/project/:id"  element={<Project />}/>
      <Route path="/project-detail/:name"  element={<ProjectDetail />}/>
      <Route path="/contact"  element={<Contact />}/>
      <Route path="/add-project"  element={<AddProject />}/>
      <Route path="/learn"  element={<Learn />}/>
      <Route path="/edit"  element={<Edit/>}/>
      <Route path="/report"  element={<Report/>}/>
      <Route path="/marketing"  element={<MarketingForm />}/>
      <Route path="/learn-detail/:id"  element={<LearingDetail />}/>
    </Routes>
    </div>
  )
}

export default App;
