import React from 'react'
import { Article } from '../components'
import Navbar from '../components/navbar/Navbar'
import { Footer } from '../containers'
import './LearingDetail.css'
import pic from '../images/bann.jpg'
import DetailCard from './DetailCard'
const LearingDetail = () => {
  return (
    <div>
      <Navbar />
      <div className='d-ban'>
        <img src={pic} />
      </div>
      <DetailCard />
      <Article />
      <Footer />
    </div>
  )
}

export default LearingDetail
