import React from 'react'
import {AiOutlineArrowRight} from 'react-icons/ai'
import Coin from './web.json'
import Lottie from 'react-lottie'
import './protocol.css'
import { Link } from 'react-router-dom'


const Protocol = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Coin,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

  return (
    <section className='communication-sec universeal'>

<div className='heading'><h1><span>Protocol of</span> Web3 </h1></div>

        <div className='communication'>
        <div className="protocol-img" >
        <Lottie 
          options={defaultOptions}
          height={350}
          width={350}
        />
        </div>

        <div className="protocol-detail" >
        <div className="about_detail">
            <h1>The Protocol of Web3</h1>
            <p>Push Protocol is a web3 communication network, enabling cross-chain notifications and messaging for dapps, wallets, and services.</p>
             <Link to="/contact"><button>Add Project <AiOutlineArrowRight/></button></Link> 
        </div>
        </div>
        </div>
    </section>
  )
}

export default Protocol
