import React, { useEffect, useState } from "react";
import "./project-Detail.css";
import pic from "../images/gradienta-QWutu2BRpOs-unsplash.jpg";
import Fade from 'react-reveal/Fade'
import { Chip,Avatar, Modal, Button } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import {BiDownArrow ,BiUpArrow} from 'react-icons/bi'
import { useParams } from "react-router-dom";
import axios from "axios";
import web from '../images/web.png'
import Website from '../images/link.png'
import white from '../images/files.png'
import 'swiper/swiper-bundle.min.css';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {AiFillLike,AiFillCheckCircle} from 'react-icons/ai'
import Popup from 'reactjs-popup';
import Loader from '../Loader'
import { Helmet } from 'react-helmet';
import truncate from 'lodash/truncate';
import {ShareSocial} from 'react-share-social' 
import Carousel from "react-elastic-carousel";
import {RiFacebookFill} from 'react-icons/ri'
import {FaLinkedinIn ,FaTwitter} from 'react-icons/fa'
import {FiGithub} from 'react-icons/fi'
import bann from "../images/bann.jpg";
import img from "../images/Bell.png"
import pic1 from '../../src/images/okxy.png';
import pic10 from '../../src/images/chains-removebg-preview.png';
import pic2 from '../../src/images/kucoin.png';
import pic3 from '../../src/images/binance.png';
import pic4 from '../../src/images/fb-f.png'
import pic6 from '../../src/images/tw-removebg-preview.png'
import pic5 from '../../src/images/linked.png'
import pic7 from '../../src/images/git.png'
import { Link } from "react-router-dom";
import { baseUrl } from "../API";
import Chat from "./Chat";
import Swipe from "./Swiper";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const ProjectDBanner = () => {
  const [count, setCount] = useState([]);
  const [buy, setbuy] = useState(false)

  const { name } = useParams();

  const [data, setData] = useState(null);

useEffect(() => {
  axios.get(baseUrl + `api/crypto-data/${name}/`)
    .then(response => {
      setData(response.data);
      console.log('kkii',response.data); // log the data to console
    })
    .catch(error => {
      console.error(error);
    });
}, [name]);



const [buys, setBuys] = useState([]);

  useEffect(() => {
    axios.get(baseUrl + "api/buy_links/")
      .then(response => setBuys(response.data))
      .catch(error => console.error(error));
  }, []);


const regex = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm;


const [showMore, setShowMore] = useState(false);


  function toggleShowMore() {
    setShowMore(!showMore);
}


const renderDescription = () => {
  let description = project.description;
  if (!showMore && project.description.length > 1325) {
    description = project.description.substring(0, 1325);
  }
  return description.replace(/<[^>]+>/g, '');
};


 
  const [project, setProject] = useState(false);

  useEffect(() => {
    axios.get(baseUrl + "api/chainlist/").then((res) => {
      console.log("Getting from ::::", res.data.banner);
      setCount(res.data.project);
    });
  }, []);


  useEffect(() => {
    axios.get(baseUrl + "api/chainlist/").then((res) => {
      // console.log("Getting from ::::", res.data.banner)
      setCount(res.data.trending);
    });
  }, []);


  const [vote, setVote] = useState(0);

  const handleClick = () => {
    setVote(vote === 0 ? 1 : 0);
  };

  useEffect(() => {
    axios
      .get(baseUrl + `api/pro-detail/${name}/`)
      .then((response) => {
        console.log("ghhhhhg",response.data)
        // console.log(project.image)
        // console.log("bb",baseUrl)
        setProject(response.data);

      })
      .catch((error) => {
        console.log(error);
      });
  }, [name]);

  if (!project) {
    return <Loader />;
  }
  function removeHtmlTags(str) {
    if (!str) return str;
    return str.replace(/<(?!\/?a(?=>|\s.*>))\/?.*?>/g, '');
  }

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];

  const brePoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 1 },
  ];



  
  const shareUrl = `https://pushecosystem.com/project-detail/${name}`;
  const shareTitle = `Check out ${project.title} on our website!`;
  const shareDescription = project.description;
  const shareImage = project.imageUrl;

  const textShadowStyle = {
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'
  };

  
  return (
    <div>
    <Helmet>
        <title>PUSH - {project.tile} </title>
        {/* <meta name="description" content="Page description" /> */}
        {/* Additional meta tags, link tags, etc. */}
      </Helmet>
    <div className="full-del">
   <Swiper className="swe"
  modules={[Navigation, Pagination, Scrollbar, A11y]}
  spaceBetween={50}
  slidesPerView={1}
  navigation
  pagination={{ clickable: true }}
  scrollbar={{ draggable: true }}
  onSlideChange={() => console.log('slide change')}
  onSwiper={(swiper) => console.log(swiper)}
>
  {project.banners.length === 0 ? (
    <SwiperSlide className='swipe'>
      <img className="del-img-1" src={bann} alt="Default Image" />
    </SwiperSlide>
  ) : (
    project.banners.map(banner => (
      <SwiperSlide className='swipe' key={banner.id}>
        <img className="del-img-1" src={baseUrl+"media/"+banner.images} alt={banner.id} />
      </SwiperSlide>
    ))
  )}

{regex.test(project.video) ? (
  <SwiperSlide className="swipe">
    <iframe
      src={project.video}
      title="YouTube video player"
      frameborder="0"
      allow=""
      allowfullscreen
    ></iframe>
  </SwiperSlide>
) : null}
</Swiper>
    
<div>
 <div className='diso'>
<div className="loc">
              <div className="tit-img">
              {project.image.toLowerCase().startsWith('https') ? (
  <img src={project.image} alt="Project Image" />
) : (
   <img src={baseUrl+"media/"+project.image} alt="Project Image"/>
)}


{/* {project.image.includes("https") ? (

<img src={project.image.replace("http://adminecosystem.uptimiseit.com/media/", "")
.replace("%3A", ":/")} className="card_img" alt="Project Image" />
) : (
 <img src={baseUrl+project.image.replace("http://adminecosystem.uptimiseit.com/", "")}  className="card_img" alt="Project Image"/>
)} */}
              
             
               <div className="details-head">
                  <h1>{project.tile}</h1>
                  <div> <p className="cat-name">{project.category}</p> </div>
                  <div> <p className="cat-des">{project.description2.substring(0,60)}</p> </div>
                  <div> <ul class="social-icons">

{project.facebook_link ? (
  <li>
    <a href={project.facebook_link}   target="_blank" class="social-icon">
      {" "}
      <RiFacebookFill />
    </a>
  </li>
) : null}



{project.linkedin_link ? (
  <li>
    <a href={project.linkedin_link}  target="_blank" class="social-icon">
      {" "}
      <FaLinkedinIn />
    </a>
  </li>
) : null}


{project.twitter_link ? (
  <li>
    <a href={project.twitter_link}  target="_blank"  class="social-icon">
      {" "}
      <FaTwitter />
    </a>
  </li>
) : null}           
 

 {project.github_url ? (   
  <li>
    <a href={project.github_url}  target="_blank" class="social-icon">
      {" "}
     <FiGithub />
    </a>
  </li>
) : null}  


{project.website_url ? (   
  <li>
    <a href={project.website_url}  target="_blank" class="social-icon">
      {" "}

   <img src={web}  className="webss"/>
   
    </a>
  </li>
) : null}  


<i class="fa-light fa-circle-w"></i>

</ul></div></div>
                  <Button className="live-butn"
  variant="contained" 
 
  style={{
    backgroundColor: project.live_status === "Live" ? "transparent" :
                       project.live_status === "Up-Coming" ? "transparent" :
                       project.live_status === "Expire" ? "transparent" :
                       "#15BD58",marginLeft:"20px", // default to green if live_status is null or undefined
    color: project.live_status === "Live" ? "rgba(48, 192, 79, 1)" :
    project.live_status === "Up-Coming" ? "yellow" :
    project.live_status === "Expire" ? "red" :
    "#15BD58",
  }} 
  // startIcon={
   
  //   <span 
  //     className="live-icon" 
  //     style={{
  //       backgroundColor: project.live_status === "Live" ? "green" :
  //                          project.live_status === "Up-Coming" ? "yellow" :
  //                          project.live_status === "Expire" ? "red" :
  //                          "#15BD58" 
  //     }}
  //   />
  // } 
 
>
 <AiFillCheckCircle style={{fontSize:"17px",marginRight:"5px", color: project.live_status === "Live" ? "rgba(48, 192, 79, 1)" :
    project.live_status === "Up-Coming" ? "yellow" :
    project.live_status === "Expire" ? "red" :
    "#15BD58",}} className="aiout" /> {project.live_status || 'live'} 
</Button>
              </div>
             
            </div>

         
         {/* <Chip className="bbt" avatar={<Avatar>C</Avatar>} label="Buy Coin Name" /> */}
<div>

{project.website_url && (
  <Link to={project.website_url} target="_blank">
    <button className="website-chip glow-text" >
     <img src={Website} className="webs"/> Website 
    </button>
  </Link>
)}
  
<button className="buy-chip glow-text"  onClick={() => setbuy(!buy)}  > {buy? <BiUpArrow className="glo"  style={{fontSize:"15px", textShadow:""}} /> : <BiDownArrow className="glo" style={{fontSize:"15px"}}  />} Buy Coin Now&nbsp; </button>




<div className={buy ? "buy-coin active" : "buy-coin"}>

{buys.map((item, index) => (
  
  <div className="buy-coun-con" key={item.title}>
    <img src={baseUrl+item.image} alt="" />
  <div className="buy-coin-card" >
    <a href={item.link} target="_blank" >
      <h4>{item.title}</h4>
      <p style={{ color: 'gray', fontSize: '11px', marginTop: '3px' , marginBottom: '5px', textDecoration: 'none'}}>{item.description}</p>
    </a>
    {/* {index < buys.length - 1 && <hr />} check if it is not the last item */}
  </div>
  </div>
))}
 
 {/* <div className="buy-coin-card">
   <h5>Lorem ipsum dolor</h5>
   <p>wwww.facebook.com</p>
 </div>
 <div className="buy-coin-card">
   <h5>Lorem ipsum dolor</h5>
   <p>wwww.facebook.com</p>
 </div >
 <div className="buy-coin-card">
   <h5>Lorem ipsum dolor</h5>
   <p>wwww.facebook.com</p>
 </div>
 <div className="buy-coin-card">
   <h5>Lorem ipsum dolor</h5>
   <p>wwww.facebook.com</p>
 </div> */}
</div>
</div>
       
        </div>
</div>

 
    <div className="frst-white">
      <div className="bfr-frst-white-sub ">
        <div className=" frst-white-sub  shad-oc">
          <div className="dist-btn">
          <div className="loc">
              <div className=" gradi">
              <h3 className="abo">About</h3>
               
                  <h2 >{project.tile}</h2>
                
              </div>
              <div>
                <button className="st-b">{project.category}</button>          
              </div>
            </div>
          
          </div>

          <div>
          {/* <div className="del-para">
          <div dangerouslySetInnerHTML={{ __html: showMore ? project.description : truncate(project.description, { length: 1300, separator: /,?\.* +/ }) }}>
</div>

{project.description.length > 1300 && (
  showMore ? (
    <button className="readmo" style={{ fontFamily: "-apple-system,system-ui,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',sans-serif" }} onClick={toggleShowMore}>Read less</button>
  ) : (
    <button className="readmo" style={{ fontFamily: "-apple-system,system-ui,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',sans-serif" }} onClick={toggleShowMore}>Read More</button>
  )
)}
</div> */}

<div className="del-para">
  <div
    className="description"
    dangerouslySetInnerHTML={{
      __html: showMore
        ? project.description
        : truncate(project.description, { length: 1300, separator: /,?\.* +/ }),
    }}
  ></div>

  {project.description.length > 1300 && (
    showMore ? (
      <button
        className="readmo"
        style={{
          fontFamily:
            "-apple-system,system-ui,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',sans-serif",
        }}
        onClick={toggleShowMore}
      >
        Read less
      </button>
    ) : (
      <button
        className="readmo"
        style={{
          fontFamily:
            "-apple-system,system-ui,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',sans-serif",
        }}
        onClick={toggleShowMore}
      >
        Read More
      </button>
    )
  )}
</div>

          </div>
        </div>
        </div>

        <div className="bfr-stat-card"> 
        <div className="stat-card">
          <div> 
            <div className="status_card_head">
              
           {project.image ?  <img src={baseUrl+'/media/'+project.image} alt="" /> : <img src={img} />}
            <h1>{project.tile}</h1>
       
            </div>
            {/* <div className="stat-card-cont">
              <div className="push_status_left">
                <h5>Status</h5>
              </div>{" "}
              <div className="push_status_right">
                <p className="">

                <Chip variant="outlined"
                size="small"
  avatar={<Avatar>
            <span 
              className="live-icon" 
              style={{
                backgroundColor: project.live_status === "Live" ? "green" :
                                   project.live_status === "Up-Coming" ? "yellow" :
                                   project.live_status === "Expire" ? "red" :
                                   "green" // default to green if live_status is null or undefined
              }}
            />
          </Avatar>} 
  label={project.live_status || 'live'} 
/>

                
                </p>
              </div>
            </div> */}
            <div className="stat-card-cont">
              <div className="push_status_left">
                <p>Chains</p>
              </div>{" "}
              <div className="push_status_right">
                <div className="push_status_right_inc">
               <img src={pic10} alt="" />
                <p className="push_right_chain">{project.chain_address}</p>
                </div>
              </div>
            </div>
            <div className="stat-card-cont">
              <div className="push_status_left">
                <p>Year</p>
              </div>{" "}
              <div className="push_status_right">
                <p>{project.year}</p>
              </div>
            </div>


            <div className="stat-card-cont">
  <div className="push_status_left">
    <p>Whitepaper Link</p>
  </div>{" "}
  <div className="push_status_right">
    {project.whitepaper_link ? (
      <a href={project.whitepaper_link} target="_blank">
        <p className="vist_lin" variant="contained" color="primary" component="a">
          <img src={white} alt="Whitepaper Icon" className="whitepaper-icon" />
          Visit here <i className="fa-solid fa-arrow-up-right-from-square"></i>
        </p>
      </a>
    ) : (
      <p className="vist_lin" variant="contained" color="primary" disabled>
        Not available
      </p>
    )}
  </div>
</div>
            {/* <div className="stat-card-cont">
              <div className="h5">
                <h5>Market Cap</h5>
              </div>{" "}
              <div className="sst">
                <p>23654</p>
              </div>
            </div> */}
<div className="stat-card-cont">
  <div className="push_status_left">
    <p>Top Exchange</p>
  </div>
  <div className="push_status_right">
    {project.okx && (
      <Link to={project.okx} target="_blank">
        <img className="push_but_1" src={pic1} alt="" />
      </Link>
    )}
    {project.kucoin && (
      <Link to={project.kucoin} target="_blank">
        <img className="push_but_1" src={pic2} alt="" />
      </Link>
    )}
    {project.binance && (
      <Link to={project.binance} target="_blank">
        <img className="push_but_1" src={pic3} alt="" />
      </Link>
    )}
    {!project.okx && !project.kucoin && !project.binance && (
      <p className="not-available">Not available</p>
    )}
  </div>
</div>



           
            <div className="stat-card-cont">
              <div className="push_status_left">
                <p>Project Announcement</p>
              </div>{" "}
              <div className="push_status_right">
                {/* <Link to={project.project_announcement}><button>visit here</button><i class="fa-solid fa-arrow-up-right-from-square"></i></Link> */}
                {project.project_announcement ? (
 <a  href={project.project_announcement}
 target="_blank"> <p className="vist_lin"
 variant="contained"
 color="primary"
 component="a"

 
>
 Visit here <i className="fa-solid fa-arrow-up-right-from-square"></i>
</p></a>
) : (
  <p className="vist_lin"
    variant="contained"
    color="primary"
    disabled
 
  >
    Not available
  </p>
)}
              </div>
            </div>
            <div className="stat-card-cont">
              <div className="push_status_left">
                <p>Partnership Type</p>
              </div>{" "}
              <div className="push_status_right">
              <p>{project.partnership_type ? project.partnership_type : "Not available"}</p>
              </div>
            </div>
            <div className="stat-card-cont">
              <div className="push_status_left">
                <p>Partnership Anouncement</p>
              </div>{" "}
              <div className="push_status_right">
   
               {/* <Link to={project.partnership_announcement_link}><button>visit here</button><i class="fa-solid fa-arrow-up-right-from-square"></i></Link> */}
               {project.partnership_announcement_link ? (
 <a href={project.partnership_announcement_link}
 target="_blank"><p className="vist_lin"
 variant="contained"
 color="primary"
 component="a"
 

>
 Visit here <i className="fa-solid fa-arrow-up-right-from-square"></i>
</p></a> 
) : (
  <p
  className="vist_lin"
    variant="contained"
    color="primary"
    disabled
   
  >
    Not available
  </p>
)}

              </div>
            </div>
            <div className="stat-card-conts">
              <div className="push_status_left">
                <p>Social links</p>
              </div>{" "}
              <div >
                <p className="">
                  {" "}
                  <div> <ul class="social-icons">

{project.facebook_link ? (
  <li>
    <a href={project.facebook_link}   target="_blank" class="social-icon">
      {" "}
      <RiFacebookFill />
    </a>
  </li>
) : null}



{project.linkedin_link ? (
  <li>
    <a href={project.linkedin_link}  target="_blank" class="social-icon">
      {" "}
      <FaLinkedinIn />
    </a>
  </li>
) : null}


{project.twitter_link ? (
  <li>
    <a href={project.twitter_link}  target="_blank"  class="social-icon">
      {" "}
      <FaTwitter />
    </a>
  </li>
) : null}           
 

 {project.github_url ? (   
  <li>
    <a href={project.github_url}  target="_blank" class="social-icon">
      {" "}
     <FiGithub />
    </a>
  </li>
) : null}  




</ul></div>
                </p>
              </div>
            </div>
            <div style={{paddingTop:"20px",display:"flex",justifyContent:"center",alignItems:"center"}}>
            <Popup
  trigger={
    <button  className="share_btun"
      variant="contained"
      
      // startIcon={<FontAwesomeIcon icon={faShareNodes} style={{color:"#fff",fontSize:"15px",}} />}
    >
     <p>Share!</p> 
    </button>
  }
  modal
  nested
>
  { close => (
    <div className="modal">
      <button className="close" onClick={close}>
        &times;
      </button>
      <div className="header"> Share</div>
      <div className="content">
        {' '}
        <ShareSocial
          url={shareUrl}
          title={shareTitle}
          description={shareDescription}
          image={shareImage}
          socialTypes={['facebook', 'twitter', 'linkedin', 'pinterest', 'whatsapp', 'telegram']}
          openWindow={true}
        />
      </div>
    </div>
  )}
</Popup>

        
           
  
            {/* <Chip avatar={<Avatar><i class="fa-solid fa-share-nodes" style={{color:"blue"}}></i></Avatar>} label="Share" /> */}
            
            <p className="vote_btun" onClick={handleClick}

>
<AiFillLike />  Vote ({vote}) 
</p>
            </div>
          </div>
        </div></div>
      </div> 




<div className="sim">
{project.description3 && (
<div className="frst-whites ">
      <div className="bfr-frst-whites-sub ">
        <div className=" frst-whites-sub  shad-oc">
         

          <div>


<div className="del-para">
  {/* <div className="description" dangerouslySetInnerHTML={{ __html: showMore ? project.description3 : truncate(project.description, { length: 1300, separator: /,?\.* +/ }) }}>
  </div> */}
  <div className="description" dangerouslySetInnerHTML={{ __html: project.description3}}></div>


  {/* {project.description.length > 1300 && (
    showMore ? (
      <button className="readmo" style={{ fontFamily: "-apple-system,system-ui,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',sans-serif" }} onClick={toggleShowMore}>Read less</button>
    ) : (
      <button className="readmo" style={{ fontFamily: "-apple-system,system-ui,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',sans-serif" }} onClick={toggleShowMore}>Read More</button>
    )
  )} */}
</div>
          </div>
        </div>
        </div>

       
      </div> 
  )}
</div>
   

   {project.symbol === "" ?   "" :  <Fade><Chat className="bg-white" /></Fade>}
      
      {project.investors && project.investors.length > 0 && (
 <Fade>
   <div className="container-invest">
    <h1>Invested By</h1>
    <div className="members">
      {project.investors.map(investor => (
        <div className="member" key={investor.id}>
          <img src={baseUrl+"/media/" + investor.image} alt={investor.name} />
          {/* {project.image.toLowerCase().startsWith('https') ? (
  <img src={project.image} alt="Project Image" />
) : (
   <img src={baseUrl+"media/"+project.image} alt="Project Image"/>
)} */}
          <h2 className="member-name">{investor.name}</h2>
          {/* <span className="member-role">Founder & CEO</span> */}
        </div>
      ))}
    </div>
  </div></Fade>
)}
      <div className="space">
  {project.related_project && project.related_project.length > 0 ? (
  <Fade><div className="related-projects">
      <h1 className="klo">Related projects</h1>
      <Carousel className="dd"
      pagination={false} breakPoints={breakPoints}>
        {project.related_project.map(related => (
          // <Link to={`/project-detail/${related.slug}`} key={related.id}>
            <Link to={`/project-detail/${related.slug}`} key={related.id}>
            
            <div className="item" key={related.id}>
              <img src={baseUrl+'/media/'+related.image} alt="" />
              <h4>{related.title}</h4>
            </div>
          </Link>
        ))}
      </Carousel>
    </div></Fade>
  ) : null}
</div>
      {/* <div className="investor">
        <div className="investors-head">
        <h1>Investors</h1>
        </div>
        <div className="investors-pics">
          <div>
            <img src={pic1} alt="" />
            <img src={pic2} alt="" />
            <img src={pic1} alt="" />
            <img src={pic2} alt="" />
            <img src={pic1} alt="" />
            <img src={pic2} alt="" /><img src={pic1} alt="" />
            <img src={pic2} alt="" />
            <img src={pic2} alt="" />
            </div>
        </div>
      </div>

      <div className="star">
        <div className="star-box">
        <div className="star-box-contant"><h3>Status</h3><p>Live</p></div>
        <div className="star-box-contant"><h3>Chains</h3><p>EIT</p><p>BSC</p></div>
        <div className="star-box-contant"><h3>Chains</h3></div>
        <div className="star-box-contant"><h3>Chains</h3></div>
        </div>
      </div>

      <div className="investor-pro-share">
        <h1 >Share Project name</h1>
        <div>
        <ul className="wrapp">
  <li className="icon facebook">
    <span className="tooltip">Facebook</span>
    <span><i className="fab fa-facebook-f"></i></span>
  </li>
  <li className="icon twitter">
    <span className="tooltip">Twitter</span>
    <span><i className="fab fa-twitter"></i></span>
  </li>
  <li className="icon instagram">
    <span className="tooltip">Instagram</span>
    <span><i className="fab fa-instagram"></i></span>
  </li>
  <li className="icon github">
    <span className="tooltip">Github</span>
    <span><i className="fab fa-github"></i></span>
  </li>
  <li className="icon youtube">
    <span className="tooltip">Youtube</span>
    <span><i className="fab fa-youtube"></i></span>
  </li>
</ul>
        </div>
      </div>

      <div className="related-projects">
    <h1>Related Projects</h1>
        <div className="related-cont">
<div className="related-projects-box">
  <img src={pic3} />
  <p>lorem</p>
</div>
<div className="related-projects-box">
  <img src={pic3} />
  <p>lorem</p>
</div>
<div className="related-projects-box">
  <img src={pic3} />
  <p>lorem</p>
</div>
<div className="related-projects-box">
  <img src={pic3} />
  <p>lorem</p>
</div>
        </div>

      </div> */}
    </div>
    </div>
  );
};

export default ProjectDBanner;
