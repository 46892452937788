
import pic from '../../images/ref-finance.jpg'
import pic1 from '../../images/aurora-dev.jpg'

import pic3 from '../../images/octopus-network.jpg'
import pic4 from '../../images/paras.jpg'
import pic5 from '../../images/sweat-economy.jpg'
import pic6 from '../../images/learn-near.jpg'
import pic7 from '../../images/ref-finance.jpg'
import pic8 from '../../images/aurora-dev.jpg'
import pic9 from '../../images/octopus-network.jpg'
import pic10 from '../../images/paras.jpg'
import pic11 from '../../images/meta-pool.jpg'
import pic12 from '../../images/sweat-economy.jpg'

const PropertyDetail = [
    {
        id:"1",
        hel:"Defi",
        Image:pic,
        bed:"Ref Finance",
        bedImage:pic1,
        bedImag:pic3,
        bedIma:pic4,
        bedIma:pic5,
        bedIm:pic6,
        bath:"Multi-purpose DeFi platform built on NEAR Protocol.",
        game:"Trisolaris",
        guest:"Bringing DeFi to the NEAR ecosystem.",
        sky:"Burrow",
        skys:" Borrow, Supply. Thrive.",
        race:"Meta Pool",
        races:"Liquid Staking Protocol built on NEAR.",

    },
    {
        id:"2",
        hel:"NFT",
        Image:pic,
        bed:"Ref Finance",
        bedImage:pic1,
        bedImag:pic3,
        bedIma:pic4,
        bedIma:pic5,
        bedIm:pic6,
        bath:"Multi-purpose DeFi platform built on NEAR Protocol.",
        game:"Trisolaris",
        guest:"Bringing DeFi to the NEAR ecosystem.",
        sky:"Burrow",
        skys:" Borrow, Supply. Thrive.",
        race:"Meta Pool",
        races:"Liquid Staking Protocol built on NEAR.",
       
    },
    {
        id:"3",
        hel:"Gaming",
        Image:pic,
        bed:"Ref Finance",
        bedImage:pic1,
        bedImag:pic3,
        bedIma:pic4,
        bedIma:pic5,
        bedIm:pic6,
        bath:"Multi-purpose DeFi platform built on NEAR Protocol.",
        game:"Trisolaris",
        guest:"Bringing DeFi to the NEAR ecosystem.",
        sky:"Burrow",
        skys:" Borrow, Supply. Thrive.",
        race:"Meta Pool",
        races:"Liquid Staking Protocol built on NEAR.",
    },
    {
        id:"4",
        hel:"Aurora Ecosystem",
        Image:pic,
        bed:"Ref Finance",
        bedImage:pic1,
        bedImag:pic3,
        bedIma:pic4,
        bedIma:pic5,
        bedIm:pic6,
        bath:"Multi-purpose DeFi platform built on NEAR Protocol.",
        game:"Trisolaris",
        guest:"Bringing DeFi to the NEAR ecosystem.",
        sky:"Burrow",
        skys:" Borrow, Supply. Thrive.",
        race:"Meta Pool",
        races:"Liquid Staking Protocol built on NEAR.",
    },
    {
        id:"6",
        hel:"Octopus Ecosystem",
        Image:pic,
        bed:"Ref Finance",
        bedImage:pic1,
        bedImag:pic3,
        bedIma:pic4,
        bedIma:pic5,
        bedIm:pic6,
        bath:"Multi-purpose DeFi platform built on NEAR Protocol.",
        game:"Trisolaris",
        guest:"Bringing DeFi to the NEAR ecosystem.",
        sky:"Burrow",
        skys:" Borrow, Supply. Thrive.",
        race:"Meta Pool",
        races:"Liquid Staking Protocol built on NEAR.",
    },
    {
        id:"7",
        hel:"Newly Updated",
        Image:pic,
        bed:"Ref Finance",
        bedImage:pic1,
        bedImag:pic3,
        bedIma:pic4,
        bedIma:pic5,
        bedIm:pic6,
        bath:"Multi-purpose DeFi platform built on NEAR Protocol.",
        game:"Trisolaris",
        guest:"Bringing DeFi to the NEAR ecosystem.",
        sky:"Burrow",
        skys:" Borrow, Supply. Thrive.",
        race:"Meta Pool",
        races:"Liquid Staking Protocol built on NEAR.",
    }
]
export default PropertyDetail