import React, { useEffect, useRef, useState } from 'react'
import { card } from '../../data'
import { Tilt } from 'react-tilt';
import { Slider } from 'infinite-react-carousel/lib';
import { baseUrl } from '../../API'
import axios from 'axios'
import pic from '../../images/Bell.png'
import { Link } from "react-router-dom";
import Carousel from 'react-elastic-carousel'
import "react-multi-carousel/lib/styles.css"

const Carou = () => {
  const [count, setCount] = useState([])
  
  useEffect(() => {
    axios.get(baseUrl+"api/chainlist/")
    .then((res) =>{
    // console.log("Getting from ::::", res.data.banner)
    setCount(res.data.trending)
    })
    },[])
    
    const breakPoints = [
      {width:1 , itemsToShow:1},
      {width:550 , itemsToShow:2},
      {width:768 , itemsToShow:4},
      {width:1200, itemsToShow:4}
    ];
    
  return (
    <div style={{marginTop:"50px"}}>
        
        <Carousel
         pagination={false}
       breakPoints={breakPoints}>
                {count.map((trending)=>{
   const {id, image, slug, tile} = trending;
                 return  (
                  <Link to={`/project-detail/${slug}`} key={id}> 
                    <Tilt> <div className="item">
                    
                    {image ? (
    <img src={baseUrl+image} alt="" />
  ) : (
  <img src={pic} />
  )}
                        <p>{tile}</p>
                    </div></Tilt></Link>)
                })}</Carousel>
            </div>
        
  )
}

export default Carou







