import React from 'react'
import './blog.css'
import { Helmet } from 'react-helmet';
import pic from '../../images/Bell.png'
import pic1 from '../../images/aurora-dev.jpg'
import { Link } from 'react-router-dom'
import pic3 from '../../images/octopus-network.jpg'
import pic4 from '../../images/paras.jpg'
import pic5 from '../../images/sweat-economy.jpg'
import pic6 from '../../images/learn-near.jpg'
import pic7 from '../../images/ref-finance.jpg'
import pic8 from '../../images/aurora-dev.jpg'
import pic9 from '../../images/octopus-network.jpg'
import pic10 from '../../images/paras.jpg'
import pic11 from '../../images/meta-pool.jpg'
import pic12 from '../../images/sweat-economy.jpg'
import Carousel from 'react-elastic-carousel'
import "react-multi-carousel/lib/styles.css"
import Fade from 'react-reveal/Fade'
import axios from 'axios'
import { useState,useEffect } from 'react'
import { baseUrl } from '../../API'
import Carou from '../possibility/Carou';
import TVL from './TVL';

const Blog = () => {
  const [count, setCount] = useState([])

  useEffect(() => {
  axios.get(baseUrl+"api/chainlist/")
  .then((res) =>{
  // console.log("Getting from ::::", res.data.banner)
  setCount(res.data.tvl)
  })
  },[])

  const breakPoints = [
    {width:1 , itemsToShow:1},
    {width:550 , itemsToShow:2},
    {width:768 , itemsToShow:3},
    {width:1200, itemsToShow:4}
  ];
  return (
<section className='universeal'>
        <div className='heading'><h1><span>TVL</span>  Projects </h1></div>

        <TVL />
    </section>

//     <div>
// <Helmet>
//  Home | Push Protocol (EPNS)    </Helmet>
//     <div className='project glass blast'>
					
//           <div className='center'><h1 > List of Active Projects Base On TVL</h1> </div>
				
// 					<Fade>
// 					<div className='pp'>
//           <Carousel pagination={false} className='dd' breakPoints={breakPoints}>
          
//  { count.map((tvl)=>{
//   const {id, image, slug, tile} = tvl;
//   return <Link to={`/project-detail/${slug}`} key={id}> <div className='dar' key={id}>{image ? (
//     <img src={baseUrl+image} alt="" />
//   ) : (
//   <img src={pic} />
//   )}<h4>{tile}</h4></div></Link>
//    })}
 
          
// </Carousel>
// </div>
// </Fade>
//           </div>
//           </div>
        
  )
}

export default Blog;
