import React, { useEffect, useState } from "react";
import './DetailCard.css'
import pic from '../images/injective-protocol.jpg'
import { baseUrl } from "../API";
import axios from "axios";
import { useParams } from "react-router-dom";

const DetailCard = () => {

  const { id } = useParams();






  const [categories, setCategories] = useState([]);

    useEffect(() => {
      axios.get(baseUrl+`api/categories/${id}/videos/`)
        .then(response => {
          setCategories(response.data);
          console.log('dddd',response.data)
        })
        .catch(error => {
          console.log(error);
        });
    }, [id]);





  return (

    <div className='lrn-card-con'>
    {categories && categories.category && categories.videos.length > 0 && (
      <div>
        <div className='lrn-card-con-head'>
          <h1>{categories.category.name}</h1>
        </div>
        <div className='lrn-card-sub-con'>
          {categories.videos.map(video => (
            <div key={video.id} className='lrn-card'>
              <iframe
                width="410"
                height="280"
                src={video.video_url}
                title={video.name}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <h3>{video.name}</h3>
            </div>
          ))}
        </div>
      </div>
    )}
  </div>
  )
}

export default DetailCard
