import React from "react";

import "./header.css";
import { Suspense } from "react";
import { motion } from "framer-motion";
// import Fade from 'react-reveal/Fade';
import img4 from "../../images/ethereum.png";
import img3 from "../../images/polygon.svg";
import img1 from "../../images/crp.png";
import img2 from "../../images/Push-Logo-Standard-Dark.png";
import img from "../../images/1inch-1inch-logo.png";

import { useState, useEffect } from "react";
import { baseUrl } from "../../API";
import Loader from "../../Loader";
import axios from "axios";
import { Canvas } from "@react-three/fiber";
import { Link } from "react-router-dom";
import Box from "../../components/Box";
import { OrbitControls, Tetrahedron } from "@react-three/drei";
import Sphare from "../../components/Polygon";
import Bg from "../../components/Bg";
import Boxa from "../../components/Boxa";
import Boxb from "../../components/Boxb";
import { Tilt } from "react-tilt";
import Typed from 'react-typed';
import {AiOutlineArrowRight} from 'react-icons/ai'
import Lot from './LotieBit'

const Header = () => {
  const [count, setCount] = useState([]);

  useEffect(() => {
    axios
      .get(baseUrl + "api/chainlist/")
      .then((res) => {
        // console.log("Getting from ::::", res.data.banner)
        setCount(res.data.banner);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data); // => the response payload
        }
      });
  }, []);

  if (count.length == 0) {
    return <Loader />;
  }

  return (

    <section className='hero_section universeal'>
    <div className='hero_section-left'>
        <h1>We bring whole <br/> <span>  <Typed
                strings={['Ecosystem','Push Solution']}
                typeSpeed={200}
                backSpeed={150}
                cursorChar=''
                loop
            /></span>  at one place.</h1>
        <p>We bring whole Ecosystem at one place. So you can explore what is available on <span>Push</span> </p>
        <div className='hero-btn'>
        <Link to='/project/list'> <button className='hero-btn-2'>Explore Now <AiOutlineArrowRight/></button></Link>
       
        </div>
        
    </div>
    <div className='hero_section-right' >
    <Lot />
    </div>
</section>
//     <div>
       
//       {count.map((banner) => (
//         <div className="push__header" id="home" key={banner.id}>
        
//           <div className="push__header-content-two">
//             <div> <h1>Push Ecosystem at one place</h1></div>
//             <div>  <p>{banner.title}</p></div>
           

//             <div className="push__header-content__input">
//               <Link to="/project/list">
//                 <motion.button
//                   initial={{ y: 600 }}
//                   className="bntn bntn-1"
//                   animate={{ y: 0 }}
//                   transition={{ type: "spring", bounce: 0.4 }}
//                   type="button"
//                 >
//                   Latest Projects
//                 </motion.button>
//               </Link>
//               <Link to="/project/list">
//                 <motion.button
//                   initial={{ y: 600 }}
//                   animate={{ y: 0 }}
//                   className="bntn bntn-2"
//                   transition={{ delay: 0.5, type: "spring", bounce: 0.4 }}
//                   type="button"
//                 >
//                   Search Projects
//                 </motion.button>
//               </Link>
//             </div>
//           </div>
//           <div className="push__header-content-one">
//          <div>
//           <Canvas className='boxi'>
//   <OrbitControls enableZoom={false} autoRotate={true} />
//   <ambientLight intensity={0.5} />
//   <directionalLight position={[-2 , 5 ,2]}  intensity={2} />
//   <Suspense fallback={null}>
//   <Box  />
//   </Suspense>
 
// </Canvas>
// </div>
//           </div>
//         </div>
//       ))}
//     </div>
  );
};

export default Header;

