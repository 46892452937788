import React from 'react'
import { TextField } from '@material-ui/core'
import axios from 'axios'
import swal from 'sweetalert'
import { useState } from 'react'

const ReportProject = () => {
  const [formValues, setFormValues] = useState({
    name: "",
    email : "",
    telegram : "",
    description : "",
     pro_name : "",
      pro_link : ""
    
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post("http://adminecosystem.uptimiseit.com:8000/api/report-form/", formValues)
      .then((response) => {
        alert("Form submitted successfully!");
        setFormValues({ email: "" });
        window.location.reload();
      })
      .catch((error) => {
        alert("Error submitting form. Please try again.");
      });
  };
  return (
    <div>
     
     <div className='project-form'>
     
      <div className='inpt-in'>
      <form className='forms' onSubmit={handleSubmit}>
        <h1>Report Project</h1>
      <TextField className='npts' type='text' name='name' label="Your name" variant='outlined' onChange={handleInputChange}/>
      <TextField className='npts' type='text' name='email' label="Email" variant='outlined' onChange={handleInputChange}/>
      <TextField className='npts' type='text' name='telegram' label='Telegram or discord username' variant='outlined' onChange={handleInputChange}/>
      <TextField className='npts' type='text' name='description' label='Username' variant='outlined' onChange={handleInputChange}/>
      <TextField className='npts' type='text' name='pro_name' label='Name of project' variant='outlined' onChange={handleInputChange}/>
      <TextField className='npts' type='text' name='pro_link' label='Link of project' variant='outlined' onChange={handleInputChange}/>
      

      <div className='bdt'>
                    <button type='submit' class="btnn"><i class="fa-solid fa-paper-plane"></i> <a href="#">Submit</a></button>
                    </div>
      </form>
      </div>
      </div>
    </div>
  )
}

export default ReportProject
