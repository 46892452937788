import React, { useEffect,useState } from 'react'
import axios from 'axios'
import './brand.css'

import pic from '../../images/Bell.png'
import pic1 from '../../images/aurora-dev.jpg'

import pic3 from '../../images/octopus-network.jpg'
import pic4 from '../../images/paras.jpg'
import pic5 from '../../images/sweat-economy.jpg'
import pic6 from '../../images/learn-near.jpg'
import pic7 from '../../images/ref-finance.jpg'
import pic8 from '../../images/aurora-dev.jpg'
import pic9 from '../../images/octopus-network.jpg'
import pic10 from '../../images/paras.jpg'
import pic11 from '../../images/meta-pool.jpg'
import pic12 from '../../images/sweat-economy.jpg'
import Carousel from 'react-elastic-carousel'
import "react-multi-carousel/lib/styles.css"
import Fade from 'react-reveal/Fade'
import { baseUrl } from '../../API'
import { Link } from "react-router-dom";
import Des from './Des'
import { card } from '../../data'
import Carou from './Carou'

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick"


const Brand = () => {

  const [count, setCount] = useState([])

  useEffect(() => {
  axios.get(baseUrl+"api/chainlist/")
  .then((res) =>{
  // console.log("Getting from ::::", res.data.banner)
  setCount(res.data.latestpro)
  })
  },[])


  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    vertical: true,
    verticalSwiping: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  return (


    <section className='universeal '>

    <div className='heading'><h1><span>Latest</span>Projects</h1></div>
    

<div className="grid_gap">
<div className='las'>
    
    
    <div>
      <Des />
    </div>
   
      <div>
      
       <Slider {...settings}>
   
  
  
  {count.map((latestpro)=>{
    const {id, image, slug, tile ,description2} = latestpro;
    const sliceDescription = (text, start, end) => {
      return text.slice(start, end);
    };

    const slicedDescription = sliceDescription(description2, 0, 100);
  
      return (
        <Link to={`/project-detail/${slug}`} key={id}> <div className="card" >
      <div className="content">
        <div className="img">
          
          
          {/* {image ? (
  <img src={baseUrl+image} alt="" />
) : (
<img src={pic} />
)} */}


{image.includes("https") ? (

<img src={image.replace("/media/", "")
.replace("%3A", ":/")} className="card_img" alt="Project Image" />
) : (
 <img src={baseUrl+image}  className="card_img" alt="Project Image"/>
)}


</div>
        <div className="details">
          <span className="name">{tile}s</span>
          <p dangerouslySetInnerHTML={{ __html: slicedDescription }}></p>   
        </div>
       
      </div>
      
    </div></Link>)
    })}
          </Slider> 
          </div>
          </div>
      <Carou />
</div>
    
        </section>
//     <div className='project glass'>
					
//           <div className='center'><h1 > Latest Projects </h1> </div>
				
// 					<Fade>
// 					<div className='pp'>
//           <Carousel
//          pagination={false}
//           className='dd' breakPoints={breakPoints}>
          
//  {count.map((latestpro)=>{
//   const {id, image, slug, tile} = latestpro;
//   return <Link to={`/project-detail/${slug}`} key={id}><div className='dar' key={id}>{image ? (
//     <img src={baseUrl+image} alt="" />
//   ) : (
//   <img src={pic} />
//   )}<h4>{tile}</h4></div></Link>
//   })}
 
          
// </Carousel>
// </div>
// </Fade>
//           </div>
        
  )
}

export default Brand
