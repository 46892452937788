import React from 'react'
import { TextField } from '@material-ui/core'
import axios from 'axios';
import { useState } from 'react';

const EditProject = () => {
  const [formValuesedit, setFormValuesedit] = useState({
   
    name:"",
    email: "",
    telegram:"",
    about_you:"",
    project_name:"",
    project_link:"",
    new_update:"",
    source:""

    
    
  });

  const handleInputChangeedit = (event) => {
    const { name, value } = event.target;
    setFormValuesedit({ ...formValuesedit, [name]: value });
  };

  const handleSubmiteditedit = (event) => {
    event.preventDefault();
    axios
      .post("http://adminecosystem.uptimiseit.com:8000/api/edit-project-request/", formValuesedit)
      .then((response) => {
        alert("Form submitted successfully!");
        setFormValuesedit({ email: "" });
        window.location.reload();
      })
      .catch((error) => {
        alert("Error submitting form. Please try again.");
      });
  };

  return (
    <div>
      <div className='project-form'>
     
      <div className='inpt-in'>
      <form className='forms' onSubmit={handleSubmiteditedit}>
        <h1>Edit Project</h1>
      <TextField className='npts' name='name' label="your name" variant='outlined' onChange={handleInputChangeedit} require='aman'/>
      <TextField className='npts' name='email' label="Email" variant='outlined' onChange={handleInputChangeedit}/>
      <TextField className='npts' name='telegram' label='Telegram or discord username' variant='outlined' onChange={handleInputChangeedit}/>
      <TextField className='npts' name='about_you' label='About you' variant='outlined' onChange={handleInputChangeedit}/>
      <TextField className='npts' name='project_name' label='Name of project' variant='outlined' onChange={handleInputChangeedit}/>
      <TextField className='npts' name='project_link' label='Link of project' variant='outlined' onChange={handleInputChangeedit}/>
      <TextField className='npts' name='new_update' type='text' label="New Update" variant='outlined' onChange={handleInputChangeedit}/>
      <TextField className='npts' name='source' type='text' label="Source of new information" variant='outlined' onChange={handleInputChangeedit}/>

      <div className='bdt'>
                    <button type='submit' class="btnn"><i class="fa-solid fa-paper-plane"></i> <a href="#">Submit</a></button>
                    </div>
      </form>
      </div>
      </div>
    </div>
  )
}

export default EditProject
