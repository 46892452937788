import React, { Fragment, useState,useEffect } from "react";
import PropertyDetail from "../push/Detail";
import "./push.css";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import axios from "axios";
import { baseUrl } from '../../API';
import imga from '../../images/Bell.png'
import CateCard from './CateCard'

const Push = () => {

  
  function removeHtmlTags(str) {
    if (!str) return str;
    return str.replace(/<[^>]*>/g, '');
  }


    const [categories, setCategories] = useState([]);

    useEffect(() => {
      axios.get(baseUrl+'api/categorypro/')
        .then(response => {
          setCategories(response.data.category);
        })
        .catch(error => {
          console.log(error);
        });
    }, []);

    const formatNumberWithCommas = (number) => {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

  return (
    <section className='universeal'>
    <div className='heading'><h1><span>Cate</span>gory</h1></div>

    <CateCard />
   </section>
    // <Fragment>
    //   <section className="demo">
    //     <div className="center">
    //       <h1> Top Categories</h1>
    //     </div>

    //     <div className="property">
    //       <div className="row">
    //       {categories.map(category => (
    //          <div className="column">
    //               <div className="single-property" key={category.id}>
    //                 <Fade>
    //                   <div className="card">
    //                     <div className="property-thumb">
    //                       <div className="p-1">
    //                         <div className="property-tag">{category.title}</div>
    //                         <Link to={`/project/${category.id}`} >
    //                         <div className="property-but">
    //                           More <i class="fa-solid fa-arrow-right"></i>
    //                         </div>
    //                         </Link>
    //                       </div>

    //                       <h3 className="text">
    //                         {category.description}
    //                       </h3>
    //                       <div className="grab-1">
    //                       <h1 className="text-down-1">Projects:</h1>
    //                         <p className="texto-1"> {category.total_projects}</p>
                          
    //                       </div>
    //                       <div className="grab-2">
    //                       <h1 className="text-down-1">24hr Vol:</h1>
    //                         <p className="texto-1">${formatNumberWithCommas(Math.trunc(category.total_volume_24h))}</p>
    //                       </div>

    //                       <div className="grab-3">
    //                     <h1 className="text-down-1">Market Cap:</h1>
    //                     <p className="texto-1">${formatNumberWithCommas(Math.trunc(category.total_marketcaps))}</p>
    //                       </div>
    //                     </div>
                       
                       
    //                       <div className="property-footer">
    //                          {category.project.map(project => (
    //                            <Link to={`/project-detail/${project.slug}`} key={project.id}>
    //                    <ul  key={project.id}>
    //                         <li>
    //                         {project.image ? <img src={baseUrl+project.image} alt="bed" /> :<img src={imga} />}
    //                         </li>
    //                         <div>
    //                           <li>
    //                             <h4>{project.title}</h4>
    //                           </li>
    //                           <li>
    //                             <p dangerouslySetInnerHTML={{__html: project.description2}}></p>
    //                           </li>
    //                         </div>
    //                       </ul></Link> 
    //                        ))}
                          
    //                     </div>
    //                   </div>
    //                 </Fade>
    //               </div>
    //             </div>
              
    //           ))}
    //       </div>
    //     </div>
    //   </section>





    // </Fragment>
  );
};

export default Push;
