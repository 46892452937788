import React, { useState, useEffect } from "react";
import { Header } from "../containers";
import "./contact.css";
import { TextField, MenuItem, InputLabel, Select } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";
// import img from "../images/Push-Logo-Standard-White.png";
import img1 from "../images/Bell.png"
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../API";
import swal from "sweetalert";
import EditProject from "../editProject/EditProject";
import ReactDOM from "react-dom";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from 'react-ckeditor-component';




const ContactSec = () => {
  
  // const submitmarket = (event) => {
  //   // event.preventDefault();
  //   const name = event.target.name.value;
  //   const email = event.target.email.value;
  //   const telegram = event.target.telegram.value;
  //   const message = event.target.message.value;
  //   axios.post("http://adminecosystem.uptimiseit.com:8000/api/market-form/", {
  //     name,
  //     email,
  //     telegram,
  //     message,
  //   });
  //   swal({
  //     title: "Successfull ✔",
  //     text: "Your form is submitted",
  //     icon: "warning",
  //     dangerMode: false,
  //   });
  // };

  const handleSubmitProject = (event) => {
    event.preventDefault(); // prevent the default form submission behavior
  
    // create a new FormData object and append the form fields to it
    const formData = new FormData(event.target);
    
    // submit the form data to the API endpoint using Axios
    axios.post('https://adminecosystem.uptimiseit.com/api/add_projects/', formData)
      .then((response) => {
        console.log(response);
        // handle the response data
      })
      .catch((error) => {
        console.log(error);
        // handle the error
      });
  };


  const [types, setTypes] = useState([]);


  useEffect(() => {
    axios.get(baseUrl+"api/types/").then(response => {
     
      setTypes(response.data.type);
    });
  }, []);

  const [chains, setChains] = useState([]);
  useEffect(() => {
    axios.get(baseUrl+"api/chains/").then(response => {
     
      setChains(response.data.chain);
    });
  }, []);



  const [cha, setCha] = useState([]);

  // useEffect(() => {
  //   const getChainData = async () => {
  //     const response = await fetch(baseUrl + "api/chains/");
  //     const data = await response.json();
  //     const chainsData = data.chain.map(chain => ({ id: chain.id, name: chain.name }));
  //     setCha(chainsData);
  //   };
  //   getChainData();
  // }, []);


  // useEffect(()=>{
  //   const getChainData= async()=>{
  
  //     const getchain=[];
  //     const response= await fetch(baseUrl + "api/chains/");
  //     const data = await response.json();
  //     console.log('sai',data.chain);
  
  //    for(let i=0; i<data.chain.length; i++)
  //     {
  //       getchain.push(data.chain[i].name);
  //       console.log('hello',getchain)
  //    }

   
  //    setCha(getchain);
  //  }
  //  getChainData();
  // },[]);
  

  useEffect(() => {
    const getChainData = async () => {
      const chainData = [];
      const response = await fetch(baseUrl + "api/chains/");
      const data = await response.json();
      console.log('sai',data.chain);
  
      for (let i = 0; i < data.chain.length; i++) {
        chainData.push({ id: data.chain[i].id, name: data.chain[i].name });
        console.log('hellos', chainData);
      }
  
      setCha(chainData);
    };
  
    getChainData();
  }, []);
  




  
  const [categories, setCategories] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    axios.get(baseUrl+"api/categories/").then(response => {
     
      setCategories(response.data.category);
    });
  }, []);

  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const submitHandler = (event) => {
    // event.preventDefault();
    const name = event.target.name.value;
    const email = event.target.email.value;
    const telegram = event.target.telegram.value;
    const message = event.target.message.value;
    axios.post("http://adminecosystem.uptimiseit.com/api/contact-form/", {
      name,
      email,
      telegram,
      message,
    });
    swal({
      title: "Successfull ✔",
      text: "Your form is submitted",
      icon: "warning",
      dangerMode: false,
    });
  };
  const submitmarket = (event) => {
    // event.preventDefault();
    const name = event.target.name.value;
    const email = event.target.email.value;
    const telegram = event.target.telegram.value;
    const message = event.target.message.value;
    axios.post("http://adminecosystem.uptimiseit.com/api/market-form/", {
      name,
      email,
      telegram,
      message,
    });
    swal({
      title: "Successfull ✔",
      text: "Your form is submitted",
      icon: "warning",
      dangerMode: false,
    });
  };

  const [formValues, setFormValues] = useState({
    name: "",
    email : "",
    telegram : "",
    description : "",
     pro_name : "",
      pro_link : ""
    
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post("http://adminecosystem.uptimiseit.com/api/report-form/", formValues)
      .then((response) => {
        alert("Form submitted successfully!");
        setFormValues({ email: "" });
        window.location.reload();
      })
      .catch((error) => {
        alert("Error submitting form. Please try again.");
      });
  };


  // Edit Form

  const [formValuesedit, setFormValuesedit] = useState({
   
    name:"",
    email: "",
    telegram:"",
    about_you:"",
    project_name:"",
    project_link:"",
    new_update:"",
    source:""

    
    
  });


  const handleInputChangeedit = (event) => {
    const { name, value } = event.target;
    
    setFormValuesedit({
      ...formValuesedit,
      [name]: value
    });
  };

  const handleSubmiteditedit = async (event) => {
    event.preventDefault();
  
    const data = new FormData();
 
    data.append('name', formValuesedit.name);
    data.append('email', formValuesedit.email);
    data.append('telegram', formValuesedit.telegram);
    data.append('about_you', formValuesedit.about_you);
    data.append('project_name', formValuesedit.project_name);
    data.append('new_update', formValuesedit.new_update);
    data.append('project_link', formValuesedit.project_link);
    data.append('source', formValuesedit.source);


    // Send the form data to the API
    const response = await fetch('http://adminecosystem.uptimiseit.com/api/edit_pro_request/', {
      method: 'POST',
      body: data,
     
    });
  
    const result = await response.json();
      console.log(result);
      // Show an alert message
      alert('Edit Form submitted successfully!');
      // Reload the page
      window.location.reload();
  };





  const [toggleState, setToggleState] = useState(1);
  const toggle = (index) => {
    setToggleState(index);
  };

  const [openState, setopenState] = useState(1);
  const open = (index) => {
    setopenState(index);
  };

  const [tabular, setTabular] = useState(false);
  const [project, projectate] = useState(1);

  const add = (index) => {
    projectate(index);
  };



//  Add Project
  
    const [formData, setFormData] = useState({
      tile: '',
      description: '',
      category_id: '',
      image: '',
      banner: '',
      email: '',
      telegram: '',
      video_link: '',
      year: '',
      website_url: '',
      fundraising_round: '',
      partnership_type: '',
      partnership_announcement_link: '',
      demo_link: '',
      whitepaper_link: '',
      facebook_link: '',
      twitter_link: '',
      linkedin_link: '',
      github_url: '',
      type_id: '',
      chains: '',
      chain_address: '',


    });
  

    const handleSubmitaddproject = async (event) => {
      event.preventDefault();
    
      const data = new FormData();
   
      data.append('tile', formData.tile);
      data.append('image', formData.image);
      for (let i = 0; i < formData.banner.length; i++) {
        data.append('banner', formData.banner[i]);
      }
      data.append('category_id', formData.category_id);
      data.append('type_id', formData.type_id);
      data.append('description', formData.description);
     
      // for (let i = 0; i < selectedData.length; i++) {
      //     formData.chains = selectedData[i]
      //     data.append('chains', formData.chains[i]);
      // }
     
      // const chainIds = [];
      // for (let i = 0; i < selectedData.length; i++) {
      //   chainIds.push(selectedData[i].id);
      // }
      // formData.chains = chainIds;
      data.append('chains', formData.chains);
      data.append('email', formData.email);
      data.append('website_url', formData.website_url);
      data.append('chain_address', formData.chain_address);
      data.append('telegram', formData.telegram);
      data.append('video_link', formData.video_link);
      data.append('year', formData.year);
      data.append('fundraising_round', formData.fundraising_round);
      data.append('partnership_type', formData.partnership_type);
      data.append('partnership_announcement_link', formData.partnership_announcement_link);
      data.append('demo_link', formData.demo_link);
      data.append('whitepaper_link', formData.whitepaper_link);
      data.append('facebook_link', formData.facebook_link);
      data.append('twitter_link', formData.twitter_link);
      data.append('linkedin_link', formData.linkedin_link);
      data.append('github_url', formData.github_url);
      data.append('status', 'pending');
      // Send the form data to the API
      const response = await fetch('https://adminecosystem.uptimiseit.com/api/add_projects/', {
        method: 'POST',
        body: data,
       
      });
    
      const result = await response.json();
        console.log(result);
        // Show an alert message
        alert('Form submitted successfully!');
        // Reload the page
        window.location.reload();
    };




   


    const handleImageChanges = (event) => {
      const imageFiles = event.target.files;
      setFormData({ ...formData, banner: imageFiles });
    };
  

    const handleImageChange = (event) => {
      const imageFile = event.target.files[0];
      setFormData({ ...formData, image: imageFile });
    };

  
    const handleInputChanges = (event) => {
      const { name, value } = event.target;
      
      setFormData({
        ...formData,
        [name]: value
      });
    };
  
    const handleCKEditorChange = (event) => {
      const newContent = event.editor.getData();
      setFormData({
        ...formData,
        description: newContent
      });
    };


    const [selectedData, setSelectedData] = useState([]);
  
    
    const handleInputChangess = (selectedOptions) => {
      if(selectedOptions && Array.isArray(selectedOptions)) {
       
        const selectedValues = selectedOptions;
      setSelectedData(selectedValues);
    }
    };
  

    // const handleInputChangess = (events) => {
    //   const chainIds = selectedChains.map((chain) => chain.id);
    
    //   setFormData({
    //     ...formData,
    //     chains: chainIds,
    //   });
    // };
  

  return (
    <div className="contact-pagr">
      <div className="contact_header">
        <div className="contact_header_sub-1">
          <div className="contact_header_sub-1-1">
            <div className="contact_head">
              <h1>Contact, Add Project, Edit project</h1>
            </div>

            <div className="contact_para-1">
              <h5>
                We put Push Projects Together Support us To Help Push Ecosystem
                Grow
              </h5>
            </div>
            <div className="contact_para-2">
              <h5>
                Want to add your project on push Ecosystem? Got updates to share
                with community? Have any question?
              </h5>
            </div>
          </div>
        </div>
        <div className="contact_header_sub-2">
          <img src={img1} />
        </div>
      </div>
      <div className="tabular">
        <button
          className={openState == 1 ? "c active" : "c"}
          onClick={() => open(1)}
        >
          {" "}
          Contact Us
        </button>
        <button
          className={openState == 2 ? "c active" : "c"}
          onClick={() => open(2)}
        >
          Project
        </button>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <hr style={{ marginBottom: "50px", width: "60%" }}></hr>
      </div>
      {/*  */}
      <div className={openState == 1 ? "contact_sec active" : "contact_sec"}>
        <div className="contact_sub_sec-1">
          <div className="contact_but">
            <button
              className={toggleState == 1 ? "ct-bt active" : "ct-bt "}
              onClick={() => toggle(1)}
            >
              {" "}
              Contact Us
            </button>
            <button
              className={toggleState === 2 ? "ct-bt active" : "ct-bt "}
              onClick={() => toggle(2)}
            >
              Marketing Enquiry
            </button>
            <button
              className={toggleState == 3 ? "ct-bt active" : "ct-bt "}
              onClick={() => toggle(3)}
            >
              Report Project
            </button>
          </div>
          <div className="contact_left_para">
            <h3>
              Let’s discuss on something{" "}
              <span style={{ color: "orangered" }}>cool</span> together
            </h3>
          </div>
        </div>
        <div className="contact_sub_sec-2">
          <div>
            <form
              className={toggleState == 1 ? "form active" : "form"}
              onSubmit={submitHandler}
            >
              <h2>Contact</h2>
              <TextField className='npts' name="name" label=" Name" />
              <TextField className='npts' name="email" type="email" label="E-mail " />
              <TextField className='npts'
                name="telegram"
                label=" Telegram or discord username"
              />
              <TextField className='npts' name="message" type="text" label="Message" />

              <div className="bdt">
                <button class="btnn" type="submit">
                  <i class="fa-solid fa-paper-plane"></i> Submit
                </button>
              </div>
            </form>
            <form
              className={toggleState == 2 ? "form active" : "form"}
              onSubmit={submitmarket}
            >
              <h2>Market</h2>
              <TextField className="npts" name="name" label=" Name" />
              <TextField
                className="npts"
                name="email"
                type="email"
                label="E-mail "
              />
              <TextField
                className="npts"
                name="telegram"
                label=" Telegram or discord username"
              />
              <TextField
                className="npts"
                name="message"
                type="text"
                label="Message"
              />

              <div className="bdt">
                <button class="btnn" type="submit">
                  <i class="fa-solid fa-paper-plane"></i> Submit
                </button>
              </div>
            </form>

            <form
              className={toggleState == 3 ? "form active" : "form"}
              onSubmit={handleSubmit}  enctype="multipart/form-data"
            >
              <h2>Report</h2>

              <TextField className='npts' type='text' name='name' label="Your name"  onChange={handleInputChange}/>
      <TextField className='npts' type='text' name='email' label="Email"  onChange={handleInputChange}/>
      <TextField className='npts' type='text' name='telegram' label='Telegram or discord username'  onChange={handleInputChange}/>
      <TextField className='npts' type='text' name='description' label='Username' onChange={handleInputChange}/>
      <TextField className='npts' type='text' name='pro_name' label='Name of project'  onChange={handleInputChange}/>
      <TextField className='npts' type='text' name='pro_link' label='Link of project' onChange={handleInputChange}/>

              <div className="bdt">
                <button class="btnn" type="submit">
                  <i class="fa-solid fa-paper-plane"></i> Submit
                </button>
              </div>
            </form>

            <div class="icons">
              <a href="#">
                <ion-icon name="logo-facebook"></ion-icon>
              </a>
              <a href="#">
                <ion-icon name="logo-instagram"></ion-icon>
              </a>
              <a href="#">
                <ion-icon name="logo-twitter"></ion-icon>
              </a>
              <a href="#">
                <ion-icon name="logo-google"></ion-icon>
              </a>
              <a href="#">
                <ion-icon name="logo-skype"></ion-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={openState == 2 ? "project-form active" : "project-form "}>
        <div className="inpt-in">
          <div className="addpro">
            <button
              className={project == 1 ? "add active" : "add"}
              onClick={() => projectate(1)}
            >
              Add Project
            </button>
            <button
              className={project == 2 ? "add active" : "add"}
              onClick={() => projectate(2)}
            >
              Edit Project
            </button>
          </div>
          <form
            className={project == 1 ? "forms active" : "forms"}
            onSubmit={handleSubmitaddproject} 
          >
            <h1>Add Project</h1>
            

            <h4>Description</h4>
            <br />
            <CKEditor
          content={formData.description}
          events={{
            change: handleCKEditorChange
          }}
          />
            <br />
            <TextField className="npts" label=" Name (name of project)" name="tile" value={formData.tile} onChange={handleInputChanges}/>
            < br />
           <h4>Choose Logo</h4>
            {/* <TextField className="npts" type="file" name="image" onChange={handleImageChange} />  */}
            <TextField type="file" name="image" onChange={handleImageChange} />
            <br />
         <h4>Choose Banners</h4>
         {/* <TextField type="file" name="banner" multiple onChange={handleImageChanges} /> */}
         <input type="file" name="banner" multiple onChange={handleImageChanges} />

<br />
         <InputLabel id="demo-simple-select-label" >Category</InputLabel>
                 <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Category"
                    name="category_id"
                    value={formData.category_id}
                    onChange={handleInputChanges}
                  >
                    {categories.map(category => (
                      <MenuItem key={category.id} value={category.id} >
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select> 
                  < br />
                  <InputLabel id="demo-simple-select-label" >Type </InputLabel>
                 <Select
                
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Type"
                    name="type_id"
                    value={formData.type_id}
                    onChange={handleInputChanges}
                  >
                    {types.map(type => (
                      <MenuItem key={type.id} value={type.id} >
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select> 
                  < br />
                  <InputLabel id="demo-simple-select-label" >Chains</InputLabel>
                 <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="chain"
                    name="chains"
            
                    value={formData.chains}
                    onChange={handleInputChanges}
                  >
                    {chains.map(chain => (
                      <MenuItem key={chain.id} value={chain.id} >
                        {chain.name}
                      </MenuItem>
                    ))}
                  </Select> 
    

                 <br />

                 


                 <TextField
              className="npts"
              type="text"
              label="Chain Address"
              name="chain_address"
              value={formData.chain_address} onChange={handleInputChanges}
            />
                 {/* <Multiselect 
                      isObject={false}
                      // onRemove={(event)=>{  console.log(event)} }
                      // onSelect={ (event)=>{ console.log(event) }}
                      value={selectedData}
                    
                      // selectedValues={formData.chains}
                      onSelect={handleInputChangess}
                      // onRemove={handleInputChangess}
                      displayValue="name"
                      options={ cha } 
                      showCheckbox
                      
                      />   */}

{/* <Multiselect 
  isObject={true}
  value={selectedData}
  onSelect={handleInputChangess}
  displayValue="name"
  options={cha} 
  showCheckbox
  name='chains'
/> */}


                  < br />
            <TextField className="npts" type="text" label="Video Link" name="video_link" value={formData.video_link} onChange={handleInputChanges}/>
            <TextField className="npts" type="text" label="Year"  name="year" value={formData.year} onChange={handleInputChanges} />


            <TextField
              className="npts"
              type="text"
              label=" Last fundraising round"
              name="fundraising_round"
              value={formData.fundraising_round} onChange={handleInputChanges}
            />
          
            <TextField
              className="npts"
              type="text"
              label=" Type of partnership"
              name="partnership_type"
              value={formData.partnership_type} onChange={handleInputChanges}
            />
            <TextField
              className="npts"
              type="text"
              label="Partnership announcement link"
              name="partnership_announcement_link"
              value={formData.partnership_announcement_link} onChange={handleInputChanges}
            />

<TextField className="npts" type="text" label="Website Url" name="website_url" value={formData.website_url} onChange={handleInputChanges} />


            <h4> Social Links</h4>
            <TextField className="npts" type="text" label="FaceBook Link" name="facebook_link" value={formData.facebook_link} onChange={handleInputChanges} />
            <TextField className="npts" type="text" label="Demo link"  name="demo_link" value={formData.demo_link} onChange={handleInputChanges}/>
            <TextField className="npts" type="text" label="Whitepaper link" name="whitepaper_link" value={formData.whitepaper_link} onChange={handleInputChanges}/>
            <TextField className="npts" type="text" label="Twitter link" name="twitter_link" value={formData.twitter_link} onChange={handleInputChanges}/>
            <TextField className="npts" type="text" label="Linkedin link" name="linkedin_link" value={formData.linkedin_link} onChange={handleInputChanges}/>
            <TextField className="npts" type="text" label="Github link" name="github_url" value={formData.github_url} onChange={handleInputChanges}/>

            <h4>Contact Details</h4>
            <TextField className="npts" type="text" label="Telegram" name="telegram" value={formData.telegram} onChange={handleInputChanges}/>
            <TextField className="npts" type="email" label="Email" name="email" value={formData.email} onChange={handleInputChanges}/>

            <div className="bdt">
              <button type="submit" class="btnn">
                <i class="fa-solid fa-paper-plane"></i>Submit
              </button>
            </div>
          </form>
         




          <form  className={project == 2 ? "forms active" : "forms"} onSubmit={handleSubmiteditedit}>
        <h1>Edit Project</h1>
      <TextField className='npts' name='name' label="your name" variant='outlined'  value={formValuesedit.name} onChange={handleInputChangeedit}/>
      <TextField className='npts' name='email' label="Email" variant='outlined'value={formValuesedit.email} onChange={handleInputChangeedit}/>
      <TextField className='npts' name='telegram' label='Telegram or discord username' variant='outlined' value={formValuesedit.telegram} onChange={handleInputChangeedit}/>
      <TextField className='npts' name='about_you' label='About you' variant='outlined' value={formValuesedit.about_you} onChange={handleInputChangeedit}/>
      <TextField className='npts' name='project_name' label='Name of project' variant='outlined' value={formValuesedit.project_name} onChange={handleInputChangeedit}/>
      <TextField className='npts' name='project_link' label='Link of project' variant='outlined' value={formValuesedit.project_link} onChange={handleInputChangeedit}/>
      <TextField className='npts' name='new_update' type='text' label="New Update" variant='outlined' value={formValuesedit.new_update} onChange={handleInputChangeedit}/>
      <TextField className='npts' name='source' type='text' label="Source of new information" variant='outlined' value={formValuesedit.source} onChange={handleInputChangeedit}/>

      <div className='bdt'>
                    <button type='submit' class="btnn"><i class="fa-solid fa-paper-plane"></i> Submit</button>
                    </div>
      </form>
        </div>
      </div>
      <div className="cont-bot">
        <h1>Thanks For Reaching out To Us</h1>
        <p>
          We are available on all social media platforms. Follow us to get
          fastest updates from all Push Ecosystem projects.
        </p>
      </div>

      <div></div>
    </div>
  );
};

export default ContactSec;
