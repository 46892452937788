import React, { useEffect, useRef, useState } from 'react'
import { card } from '../../data'
import { Tilt } from 'react-tilt';
import { Slider } from 'infinite-react-carousel/lib';
import { baseUrl } from '../../API'
import axios from 'axios'
import pic from '../../images/Bell.png'
import { Link } from "react-router-dom";
import Carousel from 'react-elastic-carousel'
import "react-multi-carousel/lib/styles.css"

const TVL = () => {

    const [count, setCount] = useState([])

    useEffect(() => {
    axios.get(baseUrl+"api/chainlist/")
    .then((res) =>{
    // console.log("Getting from ::::", res.data.banner)
    setCount(res.data.tvl)
    })
    },[])

  const breakPoints = [
    {width:1 , itemsToShow:1},
    {width:550 , itemsToShow:2},
    {width:768 , itemsToShow:4},
    {width:1200, itemsToShow:4}
  ];
    
  return (
    <div style={{marginTop:"50px"}}>
       <Carousel
         pagination={false}
       breakPoints={breakPoints}>
       
       { count.map((tvl)=>{
      const {id, image, slug, tile} = tvl;
                 return  (
                    <Tilt><Link to={`/project-detail/${slug}`} key={id}> <div className="item">
                    
                    {image.includes("https") ? (

<img src={image.replace("/media/", "")
.replace("%3A", ":/")} className="card_img" alt="Project Image" />
) : (
 <img src={baseUrl+image}  className="card_img" alt="Project Image"/>
)}
                        <p>{tile}</p>
                    </div></Link></Tilt>)
               
              })}

           
</Carousel>


            </div>
        
  )
}

export default TVL







