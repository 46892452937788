import React from 'react'
import './possibility.css'
import { useEffect,useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet';

import Slider from 'react-slick'
import Carousel from 'react-elastic-carousel'
import "react-multi-carousel/lib/styles.css"
import Fade from 'react-reveal/Fade'
import { baseUrl } from '../../API'
import Crad from './Crad'
import Carou from './Carou'
import Building  from './Builing'

const Possibility = () => {
  const [count, setCount] = useState([])

  useEffect(() => {
  axios.get(baseUrl+"api/chainlist/")
  .then((res) =>{
  // console.log("Getting from ::::", res.data.banner)
  setCount(res.data.trending)
  })
  },[])

  const breakPoints = [
    {width:1 , itemsToShow:1},
    {width:550 , itemsToShow:2},
    {width:768 , itemsToShow:3},
    {width:1200, itemsToShow:4}
  ];
  return (
    <section className='universeal'>

    <div className='heading'><h1><span>Trending</span> Projects </h1></div>
    

    <div className="grid_gap">
            <div className='latest_section'>
           
            <div className='add'> 
           
            <Crad />
            </div>
            <div className=''>
               <Building />
            </div>
    
           
        </div>
    
        <Carou />
        </div>
        </section>

//     <div>
//     <Helmet>
//      Home | Push Protocol (EPNS)    </Helmet>

//     <div className='project glass'>
					
//           <div className='center'><h1 > Trending Projects</h1> </div>

          
				
// 					<Fade >
// 					<div className='pp'>
//           <Carousel pagination={false} className='dd'  breakPoints={breakPoints}>
          
//           {count.map((trending)=>{
//   const {id, image, slug, tile} = trending;
//   return  <Link to={`/project-detail/${slug}`} key={id}><div className='dar' key={id}><img src={baseUrl+image} alt="" /><h4>{tile}</h4></div></Link>
//   })}

          
// </Carousel>
// </div>
// </Fade>
//           </div>
//         </div>
  )
}

export default Possibility;
