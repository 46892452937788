import React from 'react'
import { Article, Navbar } from './components'
import { Footer } from './containers'
import Marketing from './marketing/Marketing'

const MarketingForm = () => {
  return (
    <div className='olx'>
      <Navbar />
      <Marketing />
      <Article />
      <Footer/>
      
    </div>
  )
}

export default MarketingForm
