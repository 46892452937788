import React from 'react'
import Lottie from 'react-lottie'
import Coin from './build.json'

export default function Building() {
  const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: Coin,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
  
  return (
    <>
      <Lottie 
	    options={defaultOptions}
        height={370}
        width={390}
      />
    </>
  );
}

