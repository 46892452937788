import { Footer, Blog, Possibility, Header, Push} from './containers'
import { Cta,Brand,Navbar,Article } from './components'
import About from './containers/about/About'
import PushCards from './components/pushorg/PushOrg'
import './App.css'
import { Helmet } from 'react-helmet'
import React from 'react'
import Protocol from './components/protocol/Protocol'

const Home = () => {
  
  return (
    
    <div > 

<Helmet>
        <title>PUSH</title>
      
      </Helmet>

       <div className='gradient__bg'>
        <Navbar />
        <Header />
        <Cta />
      </div>
      
      <Brand />
     <About />
      <Possibility/>
      <PushCards />
      <Push />
      {/* <Features /> */}
      
      <Blog />
      <div className='prop'>
        <Protocol />
      <Article />
      <Footer />
     
      </div>
    </div>
  )
}

export default Home
