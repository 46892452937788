import React from 'react'
import { Article, Navbar } from './components'
import { Footer } from './containers'
import ReportProject from './reportProject/ReportProject'
import './reportProject/reportProject.css'

const Report = () => {
  return (
    <div className="">
      <Navbar />
      <ReportProject />
      <Article />
      <Footer />
    </div>
  )
}

export default Report
