import React from 'react'
import Lottie from 'react-lottie'
import Coin from './Wallet.json'
import {AiOutlineArrowRight} from 'react-icons/ai'
import './about.css'
import { Link } from 'react-router-dom'

const About = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Coin,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
  return (
    <section className='universeal'>
        <div className='heading'><h1><span>#Push</span>Ecosystem</h1></div>
        <div className='about_sec'>
        <div className="about_png">
        <Lottie 
          options={defaultOptions}
          height={400}
          width={370}
        />
        </div>
        <div className="about_detail">
            <small>#Push Ecosystem</small>
            <h1>Powered by Push</h1>
            <p>Discover an amazing community of developers integrating Push to build a powerful communication stack.</p>
           <Link to='/project/list'> <button>Explore Push <AiOutlineArrowRight/></button></Link>
        </div>
        </div>
    </section>
  )
}

export default About
