import React from 'react'
import '../marketing/marketing.css'
import { TextField } from '@material-ui/core'
import axios from 'axios'
import swal from 'sweetalert'

const Marketing = () => {
    const submitHandler = (event) => {
        // event.preventDefault();
        const name = event.target.name.value;
        const email = event.target.email.value;
        const telegram = event.target.telegram.value;
        const message = event.target.message.value;
        axios.post("http://adminecosystem.uptimiseit.com:8000/api/market-form/",{
          name,
          email,
          telegram,
          message,
        })
        swal({
          title: "Successfull ✔",
          text: "Your form is submitted",
          icon: "warning",
          dangerMode: false,
        })
      }
  return (
    <div>
    <div className='project-form'>
     
     <div className='inpt-in'>
     <form className='forms' onSubmit={submitHandler}>
       <h1>Marketing Form</h1>
     <TextField className='npts' name='name' label=" Name" variant='outlined'/>
     <TextField className='npts' name='email' type='email' label="E-mail " variant='outlined' />
     <TextField className='npts' name='telegram' label=" Telegram or discord username" variant='outlined' />
     <TextField className='npts' name='message' type='text' label="Message" variant='outlined' />
     <div className='bdt'>
                   <button class="btnn"><i class="fa-solid fa-paper-plane"></i> <a href="#">Submit</a></button>
                   </div>
     </form>
     </div>
     </div>
    </div>
  )
}

export default Marketing
