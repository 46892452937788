import React from 'react'
import { TextField } from '@material-ui/core'
import './addProject.css'

const Form = () => {
  return (
    <div>
      <div className='project-form'>
     
      <div className='inpt-in'>
      <form className='forms'>
        <h1>Submit a Project on PushEcosystem </h1>
      <TextField className='npts' label=" Name (name of project)" variant='outlined'/>
      <TextField className='npts' label="Description (detail about project)" variant='outlined' />
      <TextField className='npts' type='file' variant='outlined' />
      <TextField className='npts' type='file' variant='outlined' />
      <TextField className='npts' type='date'  variant='outlined' />
      <TextField className='npts' type='file' variant='outlined' />
      <TextField className='npts' type='text' label="Category" variant='outlined' />
      <TextField className='npts' type='text' label="tags" variant='outlined' />

      <h4> Investor details</h4>
      <TextField className='npts' type='text' label="Investors list" variant='outlined' />
      <TextField className='npts' type='text' label=" Last fundraising round" variant='outlined' />
      <h4> Push x Project Detail</h4>
      <TextField className='npts' type='text' label=" Type of partnership" variant='outlined' />
      <TextField className='npts' type='text' label="Partnership announcement link" variant='outlined' />
      <h4> Official Links</h4>
      <TextField className='npts' type='text' label="Social media link" variant='outlined' />
      <TextField className='npts' type='text' label="Demo link" variant='outlined' />
      <TextField className='npts' type='text' label="Whitepaper link" variant='outlined' />
      <h4>Contact Details</h4>
      <TextField className='npts' type='text' label="Telegram" variant='outlined' />
      <TextField className='npts' type='email' label="Email" variant='outlined' />

      <div className='bdt'>
                    <button class="btnn"><i class="fa-solid fa-paper-plane"></i> <a href="#">Submit</a></button>
                    </div>
      </form>
      </div>
      </div>
    </div>
  )
}

export default Form
