import React, { useState } from "react";
import Navbar from "../components/navbar/Navbar";
import Footer from "../containers/footer/Footer";
import Article from "../components/article/Article";
import "./learn.css";
import Carousel from "react-elastic-carousel";
import { Modal , ModalBody, ModalHeader} from "reactstrap";
import { useEffect } from "react";
import axios from "axios";
import { baseUrl } from '../API';
import { Link } from "react-router-dom";
import Loader from "../Loader";

const Learn = () => {
  const [Moadal, setModal] = useState(false)
  const breaks = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];


 
  const [firstvideos, firstsetvideos] = useState([]);

  useEffect(() => {
    axios.get(baseUrl+"api/get_cat_video/").then(response => {
     
      firstsetvideos(response.data.catvideo);
    });
  }, []);


  const [videos, setvideos] = useState([]);

  useEffect(() => {
    axios.get(baseUrl+"api/category-video/").then(response => {
     
      setvideos(response.data.categoryvideo);
    });
  }, []);


  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios.get(baseUrl+'api/categoryvideos/')
      .then(response => {
        setCategories(response.data.category);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  if(videos.length == 0){
    return <Loader />;
  }

  return (
    <div>
      <Navbar />
      <div className="push_learn_head">
        <div>
          <h1>Explore Push Through Videos</h1>
          <div className="push_learn_button">
            <button >DeFi</button> <button>NFT</button> <button>Gaming</button>
          </div>
        </div>
      </div>

      <div className="push_learn_mid">
        <div className="push_learn_mid-1" onClick={()=> setModal(true)}>
          
          {firstvideos.map(video => (
            <div><iframe 
            // width="700"
            // height="350"
            src={video.video_url}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe> </div>
          
          ))}
        </div>
        <div className="push_learn_mid-2">
          <h1 >Push</h1>
          <h4>
          We bring Whole Push Ecosystem at one place. So you can explore what is available on Push.
          </h4>
        </div>
      </div>

      <div className="learn_vieos">
        {/* <h1>Title of video</h1> */}
        <br />
        <br />
        <div className="push_learn_slider"> 
        <Carousel breakPoints={breaks}>
        
       {videos.map(categoryvideo => (
          <div className="push_learn_video" key={categoryvideo.id}>
            
            <iframe 
              width="280"
              height="180"
              src={categoryvideo.video_url}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>))}

           
        </Carousel>
        </div>
      </div>


    



   <div className="start">
   {categories.map((category, index) => (
    <div className="learn_grid" key={category.id}>
      <div className="detail-hd"> <h1>{category.title}</h1> <Link to={`/learn-detail/${category.id}`}><h3>More</h3></Link> </div>
     
      <div className="learn_grid_vid_cat">
        {index % 2 === 0 ? (
          <>
            <div className="learn_grid_col-1">
              <div className="learn_grid_left">
                <div className="push_vix_1">
                <iframe
                  // width="610"
                  // height="280"
                  src={category.project[0]?.video_url}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
                </div>
                
              </div>
            </div>
            <div className="learn_grid_col-2">
              <div className="learn_grid_right-1">
                <div className="push_vix_2">
                <iframe
                  // width="400"
                  // height="280"
                  src={category.project[1]?.video_url}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
                </div>
                
              </div>
              <div className="learn_grid_right-2">
                <div className="push_vix_3">
                <iframe
                  // width="300"
                  // height="120"
                  src={category.project[2]?.video_url}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
                </div>
               <div className="push_vix_4">
               <iframe
                  // width="300"
                  // height="120"
                  src={category.project[3]?.video_url}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
               </div>
                
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="learn_grid_col-s">
              <div className="learn_grid_left-2">
                <div className="push_vix_5">
                <iframe
                  // width="300"
                  // height="120"
                  src={category.project[0]?.video_url}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
                </div>
                
                <div className="push_vix_6">
                <iframe
                  // width="300"
                  // height="120"
                  src={category.project[1]?.video_url}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
                </div>
               
              </div>
              <div className="learn_grid_left-1">
                <div className="push_vix_7">
                <iframe
                  // width="400"
                  // height="280"
                  src={category.project[2]?.video_url}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
                </div>
                
              </div>
            </div>
            <div className="learn_grid_col-f">
              <div className="learn_grid_right">
                <div className="push_vix_8">
                <iframe
                  // width="610"
                  // height="280"
                  src={category.project[3]?.video_url}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
                </div>
                
                </div>
                </div>
              </>
        )}
        </div>

        
   </div>

    




   ))}
    </div>


    <Article />
    
     <Footer />
    </div>
  );
};

export default Learn;




