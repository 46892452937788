import React from 'react'
import { useEffect,useState } from 'react'
import { card } from '../../data'

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { baseUrl } from '../../API'
import { Link } from 'react-router-dom';
import axios from 'axios';


const Crad = () => {
  const [count, setCount] = useState([])
  
  useEffect(() => {
    axios.get(baseUrl+"api/chainlist/")
    .then((res) =>{
    // console.log("Getting from ::::", res.data.banner)
    setCount(res.data.trending)
    })
    },[])

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    vertical: true,
    verticalSwiping: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div >
      <Slider {...settings}>
  
  {count.map((trending)=>{
   const {id, image, slug, tile , description2} = trending;

   const sliceDescription = (text, start, end) => {
    return text.slice(start, end);
  };

  const slicedDescription = sliceDescription(description2, 0, 100);
    
        return (
          <Link to={`/project-detail/${slug}`} key={id}> <div className="card" >
        <div className="content">
          <div className="img">
          {image.includes("https") ? (

<img src={image.replace("/media/", "")
.replace("%3A", ":/")} className="card_img" alt="Project Image" />
) : (
 <img src={baseUrl+image}  className="card_img" alt="Project Image"/>
)}</div>
          <div className="details">
            <span className="name">{tile}</span>
            <p dangerouslySetInnerHTML={{ __html: slicedDescription }}></p> 
          </div>
         
        </div>
        
      </div></Link>)
      })}
  
 
</Slider>
    </div>
    
    
     



  )
}

export default Crad
