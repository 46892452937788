import React from 'react'

import { Article, Navbar } from '../components'
import { Footer } from '../containers'

import './addProject.css'
import Form from './Form'

const AddProject = () => {
  return (
    <div className='olx'>
  <Navbar />
<Form />
  <Article />
  <Footer />
  </div>
  )
}

export default AddProject
