import React from 'react'
import Chart from "react-apexcharts";
import { baseUrl } from "../API";
import { useState, useEffect } from 'react';
import axios from 'axios';
import pic from '../images/td.jpg'
import { useParams } from "react-router-dom";
import { Chip,Avatar } from '@material-ui/core';
import { EffectCards } from 'swiper';
import { cleanup } from '@testing-library/react';



const Chat = () => {
  
  const [count, setCount] = useState([])
  useEffect(() => {
axios.get("api/chainlist/")
.then((res) =>{
setCount(res.data.project)
})
},[])

const { name } = useParams();

const [data, setData] = useState(null);

useEffect(() => {
  axios.get(baseUrl + `api/crypto-data/${name}/`)
    .then(response => {
      setData(response.data); // log the data to console
      console.log("dd",response.data);
    })
    .catch(error => {
      console.error(error);
    });
}, [name]);


const [state, setState] = useState({
  
  chart: {
    id: "basic-bar",
  },
  xaxis: {
    categories: []
  }
})


const [series, setseries] = useState([{
    name: "Open Price",
    data: [],
  
},
{
  name: "Close Price",
  data: [],

},{
  name: "High Price",
  data: [],

},{
  name: "Low Price",
  data: [],

}]);


const [mapApi, setMapApi] = useState('');

  useEffect(() => {
    // Fetch the map_api value from the API
    axios
      .get(baseUrl + 'api/key/')
      .then((response) => {
        const apiData = response.data.apikey[0];
        console.log('api',apiData.map_api);
        setMapApi(apiData.map_api);

      })
      .catch((error) => {
        console.error(error);
        // Handle error if needed
      });
  }, []);


const [error, setError] = useState(false);


useEffect(() => {
  const price = [];
  const highPrice = [];
  const lowPrice = [];
  const closePrice = [];
  const date = [];

  function newDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "";
    }
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate().toString().padStart(2, '0');
    return `${month} ${day}`;
  }

  axios
    .get(baseUrl + `api/crypto-data/${name}/`)
    .then((response) => {
      const symbol = response.data.symbol;
      // const apiUrl = `https://api.twelvedata.com/time_series?symbol=${symbol}/USD&interval=1day&dp=1&outputsize=30&apikey=${mapApi}`;
      // console.log('ss',apiUrl);
      return axios.get(
        `https://api.twelvedata.com/time_series?symbol=${symbol}/USD&interval=1day&dp=1&outputsize=30&apikey=${mapApi}`
      );
      

    })
    .then((responses) => {
      console.log("response", responses);
      const reversedValues = responses.data.values;
      reversedValues?.map((item, index) => {
        console.log("item", item);

        price.push(item.open);
        closePrice.push(item.close);
        lowPrice.push(item.low);
        highPrice.push(item.high);
        // date.push(getDateOnly(item.datetime));
        date.push(index % 2 === 0 ? item.datetime : '');
      });

      setState({
        chart: {
          id: "basic-bar",
        },
        xaxis: {
          type: 'datetime',
          categories: date.map(newDate),
          labels: {
            rotate: 0,
            hideOverlappingLabels: false
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 0,
            opacityFrom: 0,
            opacityTo: 0,
            stops: [0, 90, 100]
          }
        },
        colors: ['#00BFFF', '#FFA500', '#000000', '#008000'],
      });


      setseries([
        {
          name: "Open Price",
          data: price,
          color: "#dcdb6b"
        },{
          name: "Close Price",
          data: closePrice,
         
          color: "#938da3"
        }, {
          name: "High Price",
          data: highPrice,
        
          color: "#00cc00" 
         
        }, {
          name: "Low Price",
          data: lowPrice,
         
          color: "#e44ea5"
        }
      ]);
     

      console.log("price", price, date);
    })
    .catch((error) => {
      console.error(error);
      setError(true);
    });
}, [mapApi,name]);


const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

  




   
  return (
    <div>
       <div className="graph-box " >

     <div className='bfr-token-bx'>
        <div className="token-bx ">
          <div className="token-bx-head">
            <img src={pic} alt="" />
            <h1>Token Details</h1>
          </div>
          <div className="token-bx-sub-head">
            {/* <h3>Push</h3> */}
            
          </div>
          <div className="token-bx-btn">
         <div className='kilost'> <p className='left_token'>Name</p></div> 
            <p className='kilos'>{data && data.name}</p>
          </div>
          <div className="token-bx-btn">
          <div className='kilost' ><p className='left_token'>Ticker</p></div>  
            <p className="kilos"> {data && data.symbol}</p>
          </div>
          <div className="token-bx-btn">
          <div className='kilost'><p className='left_token'>Price</p></div>   
            <p className="kilos"> {data && formatNumberWithCommas(data.price.toFixed(5))}</p>
          </div>
          <div className="token-bx-btn">
          <div className='kilost'> <p className='left_token'>Market Cap</p></div>
            <p className="kilos">{data && typeof data.market_cap === 'number' ? data.market_cap.toLocaleString() : '-'}
</p>
          </div>
          <div className="token-bx-btn">
          <div className='kilost'> <p className='left_token'>Market Cap Rank</p></div> 
            <p className="kilos">{data && data.cmc_rank}</p>
          </div>
          <div className="token-bx-btn">
          <div className='kilost'> <p className='left_token'>Volume 24hr</p></div>
            <p className="kilos">{data && data.volume_24h ? data.volume_24h.toLocaleString() : '-'}
</p>
          </div>

          <div className="token-bx-btn">
  <div className='kilost'>
  <p className='left_token'>Max Supply</p>
  </div>
  <p className="kilos">
  {data && typeof data.max_supply === 'number' ? data.max_supply.toLocaleString() : '-'}
</p>

</div>

          <div className="token-bx-btn">
          <div className='kilost'><p className='left_token'>TVL</p></div>
            <p className="kilos">{data && typeof data.tvl === 'number' ? data.tvl.toLocaleString() : '-'}
</p>
          </div>
          
        </div>
        </div>
        
       <div className="bfr-graphh">
        <div className="graphh">
     <>
      {error ? (
        <div className='error-box'>
         
        </div>
        
      ) : (
        <Chart
          className="graph-shd"
          options={state}
          series={series}
          type="area"
          height="605"
        />
      )}
    </>
    </div> 
    </div> 
        </div>
      </div> 
    
  )
}

export default Chat
