import React, { useState ,useEffect} from "react";
import "./navbar.css";
import { useHistory } from 'react-router-dom';
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import Fade from "react-reveal/Fade";
import { Link, NavLink } from "react-router-dom";
import pic from "../../images/Bell.png";
import axios from 'axios';

import { motion } from "framer-motion";
import { baseUrl } from "../../API";
import {FiSearch} from 'react-icons/fi'
import {BsTwitter} from 'react-icons/bs'
import{MdClose} from 'react-icons/md'
import {FaBars} from 'react-icons/fa'
import img from '../../images/Bell.png'

const Navbar = () => {

  const [isNavShowing, setIsNavShowing] = useState(false)
  const [products, setProducts] = useState([]);
  const [input,setInput] = useState(false)


  useEffect(() => {
      axios.get(baseUrl+'api/pros/').then(response => {
        console.log("ggg",response.data);
        setProducts(response.data);
      });
   
    
  }, []);



    const [searchTerm, setSearchTerm] = useState('');
 const [projects, setProjects] = useState([]);
  
    const handleSearchSubmit = (event) => {
      event.preventDefault();
      fetch(baseUrl+`api/projects-search/${searchTerm}`)
        .then(response => response.json())
        .then(data => {
          setProjects(data.projects);
        });
    };
  
    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };
  








  const [count, setCount] = useState([])

  useEffect(() => {
    axios.get(baseUrl+"api/general-setting/").then(response => {
      setCount(response.data.general);
    });
  }, []);
  const [toggleMenu, setToggleMenu] = useState(false);

  const [value,setValue] = useState("")
  
const onChange = (event) =>{
  setValue(event.target.value)
}

const onSearch = (searchTerm) =>{
  console.log('search', searchTerm)
}

  return (
    <>
   <nav>
        {/* <div><img src={img} alt=""  /></div> */}
        {count.map(projects => ( 
     
      
        <div className="push__navbar-links_logo">
          <Link to="/">
            <img src={baseUrl+projects.image} />
          </Link>
        </div>
  
        ))}
        <div className="mob_bar">

        <button className='nav__toggle-btn'>
        <FiSearch className="" onClick={() => setInput(!input)}  />
        </button>

        <button className='nav__toggle-btn' onClick={() => setIsNavShowing(!isNavShowing)}>
          {
            isNavShowing ?  <MdClose /> : <FaBars />
          }
           </button>
        </div>
        
        <div className={` ${isNavShowing ? 'show__nav nav_right' : 'hide__nav nav_right'}`}>
        
         
       
        {count.map(projects => ( <div className="nav_links" >
                <ul>
                  <NavLink className={` aman ${({isActive}) => isActive ? 'active-nav' :  ''}`} to="/"><li>Home</li></NavLink>
                  <NavLink className={` aman ${({isActive}) => isActive ? 'active-nav' :  ''}`} to="/project/list" ><li>Project</li></NavLink>
                  <NavLink className={` aman ${({isActive}) => isActive ? 'active-nav' :  ''}`} to="/learn"><li>Learning</li></NavLink>
                  <NavLink className={` aman ${({isActive}) => isActive ? 'active-nav' :  ''}`} to="/contact"><li>Contact us</li></NavLink>
                  <NavLink className={` aman ${({isActive}) => isActive ? 'active-nav' :  ''}`} to={projects.twitter_link} target="_blank"><li><BsTwitter className='twitter' /> Twitter</li></NavLink>
                 <li className='search'> <button type="submit" className="srch-btn" onClick={() => setInput(!input)} ><FiSearch className="fa_search" /></button></li>
                  <NavLink className="add-pro-push"  to="/contact">  Add project</NavLink>
                </ul>
                
                
    
                </div>))}
       
            
        </div>
        
    </nav>
    <div className={input ? "seach_overlay " : "seach_overlay nope"}    >
   
      <form  onSubmit={handleSearchSubmit}>
       
        <input placeholder="Search Projcet" className={input ? "nav-inp active" : "nav-inp " } type="text" value={value} onChange={onChange}  /> 
        <div className={input ? "dropd active" : "dropd " }>
        {products.filter(item => {
          const searchTerm = value.toLocaleLowerCase();
          const fullName = item.tile.toLocaleLowerCase();

          return searchTerm && fullName.startsWith(searchTerm)
        }).map(projects => (
         <ul  key={projects.id}>
            {<Link to={`/project-detail/${projects.slug}`}><span> <img src={projects.image} alt="" /> <li onClick={() => onSearch(value)}>{projects.tile}</li></span> </Link>}
          </ul>))}
        </div>
     
        <MdClose onClick={() => setInput(!input)}  style={{color:"#fff",position:"absolute",right:"20px",top:"20px",fontSize:"30px",cursor:"pointer"}}/>
        
      </form>
     
     
</div>
    
    {/* <div className="push__navbar">
    {count.map(projects => ( 
      <div className="push__links">
      
        <div className="push__navbar-links_logo">
          <Link to="/">
            <img src={baseUrl+projects.image} />
          </Link>
        </div>
       
      </div>
        ))}

{count.map(projects => (  <div className="push__navbar-links_containers">
          <Fade top>
            <motion.p
              initial={{ y: -400 }}
              animate={{ y: 0 }}
              transition={{ delay: 0.1, type: "spring", bounce: 0.2 }}
            >
              <NavLink activeClassName="active"  className="rob" to="/">
                Home
              </NavLink>
            </motion.p>

            <motion.p
              initial={{ y: -400 }}
              animate={{ y: 0 }}
              transition={{ delay: 0.3, type: "spring", bounce: 0.2 }}
            >
              <NavLink activeClassName="active" className="rob" to="/project/list">
                Projects
              </NavLink>
            </motion.p>
            <motion.p
              initial={{ y: -400 }}
              animate={{ y: 0 }}
              transition={{ delay: 0.5, type: "spring", bounce: 0.2 }}
            >
              <NavLink activeClassName="active" className="rob" to="/learn">
                Learning
              </NavLink>
            </motion.p>
            <motion.p
              initial={{ y: -400 }}
              animate={{ y: 0 }}
              transition={{ delay: 0.5, type: "spring", bounce: 0.2 }}
            >
              <NavLink activeClassName="active" className="rob" to="/contact">
                Contact Us
              </NavLink>
            </motion.p>
           
            <motion.p
              className="green"
              to="/"
              initial={{ y: -400 }}
              animate={{ y: 0 }}
              transition={{ delay: 0.5, type: "spring", bounce: 0.2 }}
            >
              <NavLink className="twi" activeClassName="active" target="_blank" to={projects.twitter_link}>
                <i class="fa-brands gl fa-twitter"></i> Twiter
              </NavLink>
            </motion.p>


          </Fade>
        </div>))}
      <div className="push__navbar-join">
      <form className="srch-bx" onSubmit={handleSearchSubmit}>
        <div>
        <input className={input ? "nav-inp active" : "nav-inp " } type="text" value={value} onChange={onChange}  />
        <div className={input ? "dropd active" : "dropd " }>
        {products.filter(item => {
          const searchTerm = value.toLocaleLowerCase();
          const fullName = item.tile.toLocaleLowerCase();

          return searchTerm && fullName.startsWith(searchTerm)
        }).map(projects => (
         <ul  key={projects.id}>
            {<Link to={`/project-detail/${projects.slug}`}><span> <img src={projects.image} alt="" /> <li onClick={() => onSearch(value)}>{projects.tile}</li></span> </Link>}
          </ul>))}
        </div>
        </div>

        {input ? <button type="submit" className="srch-btn" onClick={() => setInput(!input)} ><i class="fa-solid fa-xmark" aria-hidden="true"></i></button> :<button type="submit" className="srch-btn" onClick={() => setInput(!input)} ><i class="fa fa-search" aria-hidden="true"></i></button>}
      </form>
     
        <Link to="/contact">
          <button className="buttonss" type="button"> Add Projects</button>{" "}
        </Link>
      </div>

      <div className="push__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={47}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={47}
            onClick={() => setToggleMenu(true)}
          />
        )}

        {toggleMenu && (
          <div className="push__navbar-menu_container scale-up-center">
            <div className="push__navbar-menu_container-links">
              <p>
                <Link to="/">Home</Link>
              </p>

              <p>
                <Link to="/project/list">Projects</Link>
              </p>

              <p>
                <Link to="/learn">Learning</Link>
              </p>
              <p>
                <Link to="/contact">Contact Us</Link>
              </p>
              <p>
                <Link className="twi" to="/">
                  <i class="fa-brands gl fa-twitter"></i> Twiter
                </Link>
              </p>
            </div>
            <div className="push__navbar-menu_container-links-sign">
            <i class="fa fa-search" aria-hidden="true"></i>
              <button className="buttonss"  type="button">Add Projects</button>
            </div>
          </div>
        )}
      </div>
    </div> */}
  
    </>
  );
};

export default Navbar;
