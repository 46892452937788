import React from 'react'

import {AiOutlineArrowRight} from 'react-icons/ai'
import img from '../../images/svg-2.png'
import { Tilt } from 'react-tilt'
import './pushorg.css'
import { Link } from 'react-router-dom'

const PushCards = () => {
  return (
    <section className='universeal'>
         <div className='heading'><h1><span>Web3</span>  Solutions</h1></div>
         <div className="push_org">
            <div className="push_org-content"><div className="about_detail">
            <small>#Push Web3 Solutions</small>
            <h1>Grow with Push</h1>
            <p>The $PUSH token enables governance, modification of protocol fees and features, network security, and fee management among all wallets that integrate Push, including Push governance participants.</p>
           <Link to='/learn'><button>Learn about Push <AiOutlineArrowRight/></button></Link> 
        </div></div>
            <div className="push_org-cards-sec">
                <Tilt><div className='push_org-card'>
                    <h3>Push Notifications</h3>
                    <img src={img} alt="" />
                    <p>Push Notifications directly connect applications to individual users, enabling secure and personalized communication in response to customized on- and off-chain activity.</p>
                </div></Tilt>
                <Tilt><div className='push_org-card'>
                    <h3>Push Chat</h3>
                    <img src={img} alt="" />
                    <p>Push Chat is the leading decentralized, web3 native chat application with user friendly features like 1-1, group chat, videos/images, and (soon) streaming.</p>
                </div></Tilt>
                <Tilt><div className='push_org-card'>
                    <h3>Push DAO</h3>
                    <img src={img} alt="" />
                    <p>Push DAO is the home of community ownership of the Push Protocol. Push token holders can view, propose, and vote on critical developments to the protocol.</p>
                </div></Tilt>
                <Tilt><div className='push_org-card'>
                    <h3>Push Notifications</h3>
                    <img src={img} alt="" />
                    <p>Push Notifications directly connect applications to individual users, enabling secure and personalized communication in response to customized on- and off-chain activity.</p>
                </div></Tilt>
            </div>
         </div>
    </section>
  )
}

export default PushCards
