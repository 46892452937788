import React from 'react'
import { Article, Navbar } from './components'
import { Footer } from './containers'
import ProjectDBanner from './projectDetail/ProjectDBanner'



const ProjectDetail = () => {
  return (
    <div >
      <Navbar />
     <ProjectDBanner />
      <Article />
      <Footer />
    </div>
  )
}

export default ProjectDetail
