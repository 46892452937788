import React, {  useState,useEffect } from "react";
import {AiOutlineArrowRight} from 'react-icons/ai'
import { Link } from "react-router-dom";
import { category } from '../../data'
import { baseUrl } from '../../API';
import imga from '../../images/Push-Logo-Standard-White.png'
import axios from "axios";

const CateCard = () => {





    const [categories, setCategories] = useState([]);

    useEffect(() => {
      axios.get(baseUrl+'api/categorypro/')
        .then(response => {
          setCategories(response.data.category);
        })
        .catch(error => {
          console.log(error);
        });
    }, []);

    const formatNumberWithCommas = (number) => {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };




  return (
    <div className='alo'>

{categories.map(category => (
        
<div className="cardt" >
  <div className="card_head" key={category.id}>
    <div className="cate_name"> {category.image ? <img src={baseUrl+"media/"+category.image} alt="bed" /> :<img src={imga} />} <h3>{category.title}</h3></div>
    <Link to={`/project/${category.id}`} ><div className="more_button">More <AiOutlineArrowRight /></div> </Link>
  </div>
  <div className="cate_detail">


<div className='cate_detail_list'>
    <p><span> Projects:</span> {category.total_projects}</p>
    <p> <span> 24hr Vol:</span> ${formatNumberWithCommas(Math.trunc(category.total_volume_24h))}</p>
    <p> <span>Market Cap:</span> ${formatNumberWithCommas(Math.trunc(category.total_marketcaps))}</p>
</div>
  </div>
  <div className="cate_prjects_c">
  {category.project.map(project => (
  <Link className="accross" to={`/project-detail/${project.slug}`} >
    <div className="catie_projects" key={project.id}>


        <div className='catie_projects_img'>
          
          {/* {project.image ? <img src={baseUrl+project.image} alt="bed" /> :<img src={imga} />} */}

          {project.image.includes("https") ? (

<img src={project.image.replace("http://adminecosystem.uptimiseit.com/media/", "")
.replace("%3A", ":/")} className="card_img" alt="Project Image" />
) : (
 <img src={baseUrl+"media/"+project.image.replace("http://adminecosystem.uptimiseit.com/", "")}  className="card_img" alt="Project Image"/>
)}
          
          
          </div>
        <div className='catie_projects_txt'><h1>{project.title}</h1> <p dangerouslySetInnerHTML={{__html: project.description2}}></p></div>
    </div>
    </Link> 
    ))}
    {/* <div className="catie_projects">
        <div className='catie_projects_img'><img src={imga} alt="" /></div>
        <div className='catie_projects_txt'><h1>Meme</h1> <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Id, facere.</p></div>
    </div>
    <div className="catie_projects">
        <div className='catie_projects_img'><img src={imga} alt="" /></div>
        <div className='catie_projects_txt'><h1>Meme</h1> <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Id, facere.</p></div>
    </div> */}
  </div>
</div>
        
        ))}
      


    </div>
  )
}

export default CateCard
