import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../API";
import Loader from "../Loader";
import "./containers.css";
import imgs from '../images/Check.png'
import imgs1 from '../images/Green Check.png'
import imgs2 from '../images/market.avif'
import img3 from '../images/fire.png'
import {BiLinkExternal} from 'react-icons/bi'
import { Link } from "react-router-dom";

const Banner = () => {


  const [totalProjects, setTotalProjects] = useState(0);
  const [activeProjects, setActiveProjects] = useState(0);
  const [count, setCount] = useState([])

  useEffect(() => {
    axios.get(baseUrl+"api/get_total_project_count/")
      .then(response => {
        const data = response.data;
        setTotalProjects(data.total_projects);
        setActiveProjects(data.active_projects);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    axios.get(baseUrl+"api/general-setting/").then(response => {
      setCount(response.data.general);
    });
  }, []);
  

  if (totalProjects == 0) {
    return <Loader />;
  }


  return (
    <div className="pro_banner">
      <div className="box">
        <div className="pros-y"> <img src={imgs}/></div>
        <p className="paras">Total no. of Projects</p>
        <p className="value">{totalProjects}</p>
      </div>
      
      
      {count.map(projects =>{
       return<div className="box-add-shad">
         <Link to={projects.promoted_link} target="_blank">
       <div className="box-add">
       <div className="box-add-sub">
      <div className="pros-add"><img src={baseUrl+projects.promoted_image}/></div>
       <span> <p>HOT</p><img src={img3} alt="" /></span>
   
      </div>
      </div>
      </Link>
      </div>
      })}

<div className="box">
      <div className="pros-g"><img src={imgs1}/></div>
        <p className="paras">Active Projects</p>
        <p className="value"> {activeProjects}</p>
      </div>
    </div>
  );
};

export default Banner;
